import { object } from 'prop-types';
import { Icon, Text } from '@comcast-sir/ui-library';

const SavedReportsEmpty = props => {
  const { content } = props;
  const baseClass = 'sir-saved-reports-empty';

  if (!content) {
    return null;
  }

  return (
    <div className={baseClass}>
      <Icon name="file" modifier="gray" title="Report icon" />
      <Text modifier="bold" size="small">
        {content['empty-heading']}
      </Text>
      <Text element="p" size="small">
        {content['empty-description']}
      </Text>
    </div>
  );
};

SavedReportsEmpty.propTypes = {
  content: object
};

export default SavedReportsEmpty;
