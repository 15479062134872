import { array, object, string } from 'prop-types';
import {
  Font,
  Page,
  Link,
  Text,
  Image,
  View,
  Document,
  StyleSheet
} from '@react-pdf/renderer';

import ReportStyles, {
  MapStyles,
  QuoteStyles,
  CityReportStyles
} from './ReportStyles';

import ReportLegend from './ReportLegend';
import logo from '../../assets/images/logo-comcast.png';
import quote from '../../assets/images/quote.png';

import ComcastNewVisionRegular from '../../assets/fonts/ComcastNewVision-Regular.woff2';
import ComcastNewVisionBold from '../../assets/fonts/ComcastNewVision-Bold.woff2';
import ComcastNewVisionLight from '../../assets/fonts/ComcastNewVision-Light.woff2';
import ComcastNewVisionMedium from '../../assets/fonts/ComcastNewVision-Medium.woff2';
import ComcastNewVisionSemiBold from '../../assets/fonts/ComcastNewVision-SemiBold.woff2';

Font.register({
  family: 'Comcast New Vision',
  fonts: [
    { src: ComcastNewVisionLight, fontWeight: 300 },
    { src: ComcastNewVisionRegular, fontWeight: 400 },
    { src: ComcastNewVisionMedium, fontWeight: 500 },
    { src: ComcastNewVisionSemiBold, fontWeight: 600 },
    { src: ComcastNewVisionBold, fontWeight: 700 }
  ]
});

Font.registerHyphenationCallback(word => [word]);

const styles = StyleSheet.create(ReportStyles);
const cityReportStyles = StyleSheet.create(CityReportStyles);
const mapStyles = StyleSheet.create(MapStyles);
const quoteStyles = StyleSheet.create(QuoteStyles);

const captureStrWithBracketArrayGroup = str => {
  const patternToMatch = str.match(/(.+)\[(.+)\](.+)/);

  if (patternToMatch && patternToMatch.length > 1) {
    return patternToMatch;
  }
  return '';
};

const CityOverView = ({ overview, city }) => {
  const cityOverviewText =
    overview && captureStrWithBracketArrayGroup(overview);

  if (!cityOverviewText) {
    return null;
  }

  return (
    <View>
      <Text style={cityReportStyles.cityOverviewText}>
        {cityOverviewText[1]}
        <Text style={cityReportStyles.cityOverviewFigure}>{city}</Text>
        {cityOverviewText[3]}
      </Text>
    </View>
  );
};

CityOverView.propTypes = {
  overview: string,
  city: string
};

const ReportDocument = props => {
  const { report, stats, overview } = props;
  const isQuote = report.featureType === 'quote';
  const featuredImage = report.image || quote;
  const investment =
    report?.directInvestment &&
    captureStrWithBracketArrayGroup(report.directInvestment);
  const moreInfoUrl = report.moreInfo && report.moreInfo.split(' ').pop();
  const moreInfo = report.moreInfo && report.moreInfo.replace(moreInfoUrl, '');
  const title = `${report.reportName}: ${report.stateName}`;
  const headerSubTitle = report?.cityName ? report.cityName : report.stateName;
  const photoHeaderImg = report?.photoHeader?.image?.url;

  const reportStyles = report?.cityName ? cityReportStyles : styles;

  const statsNumUpSpecificStyling = statSize => {
    switch (statSize) {
      case 3:
        return { ...styles.statsBlock, ...cityReportStyles.statsBlockThreeUp };
      case 4:
        return { ...styles.statsBlock, ...cityReportStyles.statsBlockFourUp };
      default:
        return styles.statsBlock;
    }
  };

  const statsBlockStyling = report?.cityName
    ? statsNumUpSpecificStyling(report?.stats.length)
    : styles.statsBlock;

  const InvestmentWrapper = () => (
    <View style={styles.investmentWrapper}>
      <Text style={styles.investmentText}>
        {investment && investment[1]}
        <Text style={styles.investmentFigure}>
          {investment && investment[2]}
        </Text>
        {investment && investment[3]}
      </Text>
    </View>
  );

  const PhotoHeaderWrapper = () => (
    <View>
      <Image src={photoHeaderImg} />
      <View>
        <Text style={styles.photoHeaderCaption}>
          {report.photoHeader.caption}
        </Text>
      </View>
    </View>
  );

  return (
    <Document>
      <Page title={title} size="LETTER" style={styles.page} wrap={false}>
        <View style={reportStyles.report}>
          {!report?.cityName && <View style={styles.leftBorder}></View>}
          <View style={styles.header}>
            <View style={styles.headerContent}>
              <Text style={styles.headerTitle}>{report.reportName}</Text>
              <Text style={styles.headerSubtitle}>{headerSubTitle}</Text>
            </View>
            <View style={styles.headerImage}>
              <Image src={logo} />
            </View>
          </View>
          <View style={styles.sectionWrapper}>
            <View style={styles.section}>
              {report?.cityName && report?.photoHeader ? (
                <PhotoHeaderWrapper />
              ) : (
                <InvestmentWrapper />
              )}
              <View style={styles.stats}>
                {stats.map(stat => (
                  <View key={stat.id} style={statsBlockStyling}>
                    <Text style={styles.statsTitle}>{stat.figure}</Text>
                    <Text style={styles.statsBody}>{stat.caption}</Text>
                    <Text style={styles.statsFootnote}>{stat.footnote}</Text>
                  </View>
                ))}
              </View>
              {report.featureType === 'map' ? (
                <View style={mapStyles.featured}>
                  <View style={mapStyles.captionWrapper}>
                    <Text style={styles.captionHeading}>
                      {report.captionHeading}
                    </Text>
                    <View style={mapStyles.captionWrapperInner}>
                      <ReportLegend
                        legend={report.legend}
                        legendColors={report.legendColors}
                        caption={report.caption}
                      />
                      {report.caption ? (
                        <Text style={styles.caption}>{report.caption}</Text>
                      ) : null}
                    </View>
                  </View>
                  <View style={mapStyles.featuredImageWrapper}>
                    <Image style={mapStyles.featuredImage} src={report.image} />
                  </View>
                </View>
              ) : (
                <View style={styles.featured}>
                  <View
                    style={
                      isQuote
                        ? quoteStyles.featuredImageWrapper
                        : styles.featuredImageWrapper
                    }
                  >
                    <Image
                      style={
                        isQuote
                          ? quoteStyles.featuredImage
                          : styles.featuredImage
                      }
                      src={featuredImage}
                    />
                  </View>
                  <View
                    style={
                      isQuote
                        ? quoteStyles.captionWrapper
                        : styles.captionWrapper
                    }
                  >
                    <Text style={styles.caption}>{report.caption}</Text>
                    {report.hyperLink ? (
                      <Text style={styles.caption}>
                        <a
                          style={styles.learnMore}
                          href={report.hyperLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Learn more
                        </a>{' '}
                        <span style={{ fontWeight: '800' }}>&gt;</span>
                      </Text>
                    ) : null}
                    {report.author ? (
                      <Text style={styles.author}>{`- ${report.author}`}</Text>
                    ) : null}
                    {report.authorCompany ? (
                      <Text style={styles.authorCompany}>
                        {report.authorCompany}
                      </Text>
                    ) : null}
                  </View>
                </View>
              )}
            </View>
            <View style={styles.footer}>
              <View style={styles.moreInfoWrapper}>
                <Image src={report.qrCodeUrl} style={reportStyles.qrCode} />
                <View style={styles.moreInfoText}>
                  <Text style={styles.moreInfo}>{moreInfo}</Text>
                  <Link
                    style={styles.moreInfoUrl}
                    src={`https://${moreInfoUrl}`}
                  >
                    {moreInfoUrl}
                  </Link>
                </View>
              </View>
              <View style={reportStyles.copyrightWrapper}>
                {report?.cityName && (
                  <CityOverView city={report.cityName} overview={overview} />
                )}
                <Text style={styles.copyright}>{report.copyright}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

ReportDocument.propTypes = {
  report: object,
  stats: array,
  overview: string
};

ReportDocument.defaultProps = {
  stats: []
};

export default ReportDocument;
