import { useEffect, useRef } from 'react';
import { string } from 'prop-types';
import { Card, Text } from '@comcast-sir/ui-library';

const ReportError = props => {
  const { error } = props;
  const cardRef = useRef(null);
  const baseClass = 'sir-report-error';

  useEffect(() => {
    const element = cardRef.current;

    if (error && element) {
      element.focus();
    }
  }, [error, cardRef.current]);

  if (!error) {
    return null;
  }

  return (
    <Card className={baseClass} modifier="error" tabIndex="0" ref={cardRef}>
      <Text element="p" size="small">
        {error}
      </Text>
    </Card>
  );
};

ReportError.propTypes = {
  error: string
};

export default ReportError;
