import { array, bool, object, oneOf, string } from 'prop-types';
import cx from 'classnames';

import { captureStrWithBracketArrayGroup } from '../../utils.js';

import Text from '../Text/Text';
import DirectInvestment from '../DirectInvestment/DirectInvestment';
import FeaturedContent from '../FeaturedContent/FeaturedContent';
import StatGrid from './StatGrid';

const baseClass = 'sir-report-preview';
const footerClass = `${baseClass}-footer`;

const CityOverView = ({ overview, city }) => {
  const cityOverviewText =
    overview && captureStrWithBracketArrayGroup(overview);

  return (
    <Text element="p" size="tiny" className={`${footerClass}-city-overview`}>
      {cityOverviewText[1]}
      <Text
        element="span"
        size="tiny"
        className={`${footerClass}-city-overview-figure`}
      >
        {city}
      </Text>
      {cityOverviewText[3]}
    </Text>
  );
};

CityOverView.propTypes = {
  overview: string,
  city: string
};

export const DEFAULT_CONTENT = {
  reportName: '2024 State Investment Report',
  stateName: 'State Name',
  cityName: '',
  directInvestment:
    'We have invested [$0.0 billion in State Name], including capital expenditures, employee wages and benefits, taxes and fees, and charitable giving in the last three years.',
  caption:
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat mas dvjsdjvnlnn svsdknd.',
  captionHeading: 'Our Presence',
  hyperLink: '',
  moreInfo: 'Scan to visit ',
  moreInfoUrl: 'ComcastCorporation.com',
  copyright:
    '© 2024 Comcast Corporation. All rights reserved. This document is intended for the recipient’s personal use only and may not be reproduced, published, or further distributed without Comcast’s written permission. Data is rounded and based on end-of-year 2020 information.'
};

const ReportPreview = (props) => {
  const {
    content,
    featureType,
    featuredImage,
    frame,
    legend,
    legendColors,
    loaded,
    logo,
    mini,
    qrCode,
    stats,
    photoHeader,
    showBorder,
    footerOverview
  } = props;
  const miniClass = mini && `${baseClass}--mini`;
  const noLeftPaddingClass = !showBorder && `${baseClass}--no-left-padding`;
  const headerClass = `${baseClass}-header`;
  const borderClass = showBorder ? `${baseClass}-border` : '';
  const includesPhotoHeader =
    content?.cityName && photoHeader && photoHeader.image;
  const qrImgSize = includesPhotoHeader ? '38px' : '30px';
  // eslint-disable-next-line
  const fake = frame;

  return (
    <div className={cx(baseClass, miniClass, noLeftPaddingClass)}>
      <div className={borderClass} />
      <header className={headerClass}>
        <div className={`${headerClass}-content`}>
          <Text element="h1" className={`${baseClass}-title`}>
            {content.reportName}
          </Text>
          <Text element="h2" className={`${baseClass}-state`}>
            {content.cityName ? content.cityName : content.stateName}
          </Text>
        </div>
        <img
          className={`${baseClass}-logo`}
          alt="Comcast NBCUniversal logo"
          src={logo}
        />
      </header>
      <div className={`${baseClass}-section-wrapper`}>
        <div className={`${baseClass}-section`}>
          {includesPhotoHeader ? (
            <FeaturedContent
              featureType="photoheader"
              caption={photoHeader.caption}
              featuredImage={photoHeader.image}
            />
          ) : (
            <DirectInvestment content={content.directInvestment} />
          )}
          <StatGrid stats={stats} loaded={loaded} />
          <FeaturedContent
            author={content.author}
            hyperLink={content.hyperLink}
            authorCompany={content.authorCompany}
            caption={content.caption}
            captionHeading={content.captionHeading}
            legend={legend}
            legendColors={legendColors}
            featureType={featureType}
            featuredImage={featuredImage}
          />
        </div>
        <footer className={footerClass}>
          <div className={`${footerClass}-more-info-wrapper`}>
            <img
              className={`${footerClass}-qr ${
                includesPhotoHeader ? `${footerClass}-qr--lg` : ''
              }`}
              src={qrCode}
              alt="scannable QR Code"
              height={qrImgSize}
              width={qrImgSize}
            />
            <div className={`${footerClass}-more-info-text-wrapper`}>
              <Text
                className={`${footerClass}-more-info`}
                element="p"
                modifier="bold"
                type="display"
                size="small"
              >
                {content.moreInfo}
              </Text>
              <Text
                className={`${footerClass}-more-info`}
                element="p"
                modifier="bold"
                type="display"
                size="small"
              >
                <a href={`https://${content.moreInfoUrl}`}>
                  {content.moreInfoUrl}
                </a>
              </Text>
            </div>
          </div>
          <div
            className={`${footerClass}-copyright-wrapper ${
              includesPhotoHeader
                ? `${footerClass}-copyright-wrapper--wide`
                : ''
            }`}
          >
            {includesPhotoHeader && (
              <CityOverView overview={footerOverview} city={content.cityName} />
            )}
            <Text
              className={`${footerClass}-copyright`}
              element="p"
              size="tiny"
            >
              {content.copyright}
            </Text>
          </div>
        </footer>
      </div>
    </div>
  );
};

ReportPreview.propTypes = {
  content: object.isRequired,
  featureType: oneOf(['map', 'photo', 'quote']),
  featuredImage: string.isRequired,
  frame: string.isRequired,
  legend: string,
  legendColors: array,
  loaded: bool,
  logo: string.isRequired,
  mini: bool,
  stats: array.isRequired,
  photoHeader: object,
  qrCode: string.isRequired,
  showBorder: bool,
  footerOverview: string
};

ReportPreview.defaultProps = {
  content: DEFAULT_CONTENT,
  loaded: true,
  mini: false,
  showBorder: true
};

export default ReportPreview;
