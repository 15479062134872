import {
  Accordion,
  Button,
  FeaturedContent,
  RadioGroup,
  Text
} from '@comcast-sir/ui-library';
import { func, object } from 'prop-types';

import {
  useContentByScreen,
  useViewport,
  useSelectedPhotoHeader
} from '../../hooks';

const SelectPhotoHeaderAccordion = props => {
  const { onConfirm, reportContent } = props;
  const [data, photoHeaderContent, actions] = useSelectedPhotoHeader(
    reportContent,
    onConfirm
  );

  const content = useContentByScreen('report-builder');
  const photoHeaderImage = photoHeaderContent && photoHeaderContent.image;
  const baseClass = 'sir-accordion-content-photoheader';
  const { width } = useViewport();
  const isDesktop = width >= 991;

  return (
    <Accordion
      checked={data.confirmed}
      expanded={data.expanded}
      id="content-selection"
      label="Step 2: Select Photo Header"
      onClick={actions.toggle}
      trigger={
        <div
          key="photoheader-trigger"
          className="sir-accordion-content-featured-selection"
        >
          <Text size="small" type="display">
            {data.label}
          </Text>
        </div>
      }
    >
      <div className={`${baseClass}-body`}>
        <Text element="p" modifier="bold">
          {content['photoheader-heading']}
        </Text>
        <Text element="p">{content['photoheader-description']}</Text>
        <div className="sir-featured-options-preview">
          <div className="sir-featured-options">
            {data.options && data.options.length ? (
              <RadioGroup
                name="photoheader"
                options={data.options}
                onChange={actions.handleChange}
              />
            ) : null}
          </div>
          <div
            className={`sir-featured-preview ${
              isDesktop ? 'sir-report-preview--mini' : ''
            }`}
          >
            <FeaturedContent
              {...photoHeaderContent}
              featureType="photoheader"
              featuredImage={photoHeaderImage}
            />
          </div>
        </div>
      </div>
      <footer className="sir-report-builder-footer">
        <Button modifier="primary" size="large" onClick={actions.handleConfirm}>
          Confirm Selection
        </Button>
      </footer>
    </Accordion>
  );
};

SelectPhotoHeaderAccordion.propTypes = {
  onConfirm: func.isRequired,
  reportContent: object
};

export default SelectPhotoHeaderAccordion;
