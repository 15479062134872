import { PDFViewer } from '@react-pdf/renderer';
import { object } from 'prop-types';

const ReportViewer = props => {
  const { reportDocument } = props;
  return (
    <PDFViewer width="100%" height="610" showToolbar={true}>
      {reportDocument}
    </PDFViewer>
  );
};

ReportViewer.propTypes = {
  reportDocument: object
};

export default ReportViewer;
