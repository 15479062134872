import { forwardRef } from 'react';
import cx from 'classnames';
import { bool, oneOf, string } from 'prop-types';
import Icon from 'components/Icon/Icon';

export const INPUT_PROPS = {
  size: ['large', 'medium', 'small'],
  status: ['default', 'success', 'error']
};

// eslint-disable-next-line
const Input = forwardRef((props, ref) => {
  const {
    hideLabel,
    id,
    type,
    label,
    placeholder,
    size,
    message,
    status,
    value,
    ...otherProps
  } = props;

  const inputClass = 'sir-form-field-input';
  const statusClass = `${inputClass}--${status}`;
  const inputElementClass = `${inputClass}-element`;
  const messageClass = status ? `${inputClass}-${status}-message` : '';
  const typeClass = `sir-form-field-input--${type}`;
  const sizeClass = size && type !== 'textarea' ? `${inputClass}--${size}` : '';
  const hiddenClass = hideLabel && 'sir-accessibly-hidden';

  const renderIcon = () => {
    const iconName = status === 'error' ? 'alert' : 'check';

    if (status === 'error' || status === 'success') {
      return (
        <div className="sir-icon-wrapper">
          <Icon name={iconName} modifier="white" title={status} />
        </div>
      );
    }

    return null;
  };

  const renderElement = () => {
    const inputProps = {
      id,
      type,
      value,
      placeholder,
      ...otherProps
    };

    return type === 'textarea' ? (
      <textarea {...inputProps} className={inputElementClass} ref={ref} />
    ) : (
      <input
        {...inputProps}
        type={type}
        className={inputElementClass}
        ref={ref}
      ></input>
    );
  };

  return (
    <div className="sir-form-field">
      <label className={cx('sir-form-field-label', hiddenClass)} htmlFor={id}>
        {label}
      </label>
      <div
        className={cx(
          'sir-form-field-input',
          typeClass,
          sizeClass,
          statusClass
        )}
      >
        {renderIcon()}
        {renderElement()}
      </div>
      {message && status !== 'default' && (
        <span className={messageClass} role="alert">
          {message}
        </span>
      )}
    </div>
  );
});

Input.propTypes = {
  /**
   * accessibly hide input label
   */
  hideLabel: bool,
  /**
   * unique identifier for input
   */
  id: string.isRequired,

  /**
   * text input type
   */
  type: string.isRequired,

  /**
   * label text
   */
  label: string.isRequired,

  /**
   * string shown beneath the input field
   */
  message: string,

  /**
   * current input value
   */
  value: string,

  /**
   * input placeholder text
   */
  placeholder: string,

  /**
   * input size variant
   */
  size: oneOf(INPUT_PROPS.size),

  /**
   * input status modifier
   */
  status: oneOf(INPUT_PROPS.status)
};

Input.defaultProps = {
  type: 'text',
  size: 'large',
  status: 'default'
};

export default Input;
