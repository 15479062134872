import { cloneElement, useState } from 'react';
import { node, oneOf, string } from 'prop-types';
import cx from 'classnames';

import Card, { CARD_PROPS } from '../Card/Card';
import Text from '../Text/Text';

const Tooltip = (props) => {
  const { children, id, modifier, title, trigger } = props;
  const [visible, setVisible] = useState(false);
  const baseClass = 'sir-tooltip';
  const visibleClass = visible && `${baseClass}--visible`;
  const cardClass = `${baseClass}-card`;
  const handleLeave = () => {
    setVisible(false);
  };
  const handleEnter = () => {
    setVisible(true);
  };

  return (
    <div className={cx(baseClass, visibleClass)}>
      <Card
        id={id}
        className={cardClass}
        modifier={modifier}
        role="tooltip"
        hidden={!visible}
      >
        <Text className={`${cardClass}-content`} element="p" size="xsmall">
          {title ? <strong>{title}</strong> : null}
          {children}
        </Text>
      </Card>
      {cloneElement(trigger, {
        key: id,
        onMouseEnter: handleEnter,
        onMouseLeave: handleLeave,
        ['aria-labelledby']: id
      })}
    </div>
  );
};

Tooltip.propTypes = {
  children: node.isRequired,
  id: string.isRequired,
  modifier: oneOf(CARD_PROPS.modifier),
  title: string,
  trigger: node.isRequired
};

Tooltip.defaultProps = {
  modifier: 'default'
};

export default Tooltip;
