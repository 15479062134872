import { array, func, string } from 'prop-types';
import Radio from './Radio';

const RadioGroup = (props) => {
  const { name, onChange, options } = props;
  const baseClass = 'sir-radio-group';

  return (
    <div className={baseClass}>
      {options.map((option) => (
        <Radio
          key={option.id}
          name={name}
          onChange={onChange.bind(null, option)}
          {...option}
        />
      ))}
    </div>
  );
};

RadioGroup.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  options: array.isRequired
};

export default RadioGroup;
