import cx from 'classnames';
import { arrayOf, shape, string } from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import Button from '../Button/Button';
import Card from '../Card/Card';

const ActionMenu = (props) => {
  const { actions, triggerLabel } = props;
  const [expanded, setExpanded] = useState(false);
  const menu = useRef();
  const baseClass = 'sir-action-menu';
  const expandedClass = expanded && `${baseClass}--expanded`;
  const handleClickOutside = () => {
    setExpanded(false);
  };

  useEffect(() => {
    if (expanded) {
      document.body.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [expanded]);

  return (
    <div className={cx(baseClass, expandedClass)}>
      <Button
        className={`${baseClass}-trigger`}
        modifier="secondary"
        onClick={() => setExpanded(!expanded)}
      >
        <span className={`${baseClass}-trigger-label`}>{triggerLabel}</span>
      </Button>
      {expanded ? (
        <Card className={`${baseClass}-card`}>
          <ul className={`${baseClass}-card-list`} ref={menu}>
            {actions.map((action) => {
              const { label, ...otherProps } = action;

              return (
                <li key={label}>
                  <Button {...otherProps}>{label}</Button>
                </li>
              );
            })}
          </ul>
        </Card>
      ) : null}
    </div>
  );
};

ActionMenu.propTypes = {
  actions: arrayOf(shape({ label: string.isRequired })),
  triggerLabel: string
};

ActionMenu.defaultProps = {
  triggerLabel: 'Actions'
};

export default ActionMenu;
