import { func, object } from 'prop-types';
import {
  Accordion,
  Button,
  SkeletonLoader,
  Text
} from '@comcast-sir/ui-library';
import { useReport } from '../../hooks';
import { CITY, STATE } from '../../constants';

const SelectStateAccordion = props => {
  const { onChangeState, selectedState } = props;
  const stateName = selectedState && selectedState.name;
  const cityName = selectedState && selectedState.city;
  const loaded = Boolean(stateName || cityName);
  const savedReport = useReport();

  const reportType = cityName ? CITY : STATE;

  return (
    <Accordion
      checked
      disabled
      id="state-selection"
      label={`Step 1: Select ${reportType}`}
      trigger={
        <div key="state-trigger" className="sir-accordion-content-state-change">
          {loaded ? (
            <Text size="small" type="display" style={{ fontWeight: 500 }}>
              {cityName ? cityName : stateName}
            </Text>
          ) : (
            <SkeletonLoader width="150px" height="30px" />
          )}
          {!savedReport ? (
            <Button modifier="link" size="xsmall" onClick={onChangeState}>
              Change {reportType}
            </Button>
          ) : null}
        </div>
      }
    />
  );
};

SelectStateAccordion.propTypes = {
  onChangeState: func.isRequired,
  selectedState: object
};

export default SelectStateAccordion;
