import { bool, node } from 'prop-types';
import cx from 'classnames';

import Text from '../Text/Text';

const Heading = (props) => {
  const { children, withDivider, withLinebreak, ...otherProps } = props;
  const baseClass = 'sir-type-heading';
  const dividerClass = withDivider ? `${baseClass}--divider` : '';
  const headingParts = children && children.split('\n');

  if (withLinebreak && headingParts && headingParts.length > 1) {
    return (
      <Text
        className={cx(baseClass, dividerClass)}
        {...otherProps}
        type="display"
        modifier="bold"
      >
        <span className={`${baseClass}-text`}>
          {headingParts[0]}
          <br />
          {headingParts[1]}
        </span>
      </Text>
    );
  }

  return (
    <Text
      className={cx(baseClass, dividerClass)}
      {...otherProps}
      type="display"
      modifier="bold"
    >
      <span className={`${baseClass}-text`}>{children}</span>
    </Text>
  );
};

Heading.propTypes = {
  children: node,
  withDivider: bool,
  withLinebreak: bool
};

Heading.defaultProps = {
  withDivider: false,
  withLinebreak: false
};

export default Heading;
