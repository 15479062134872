import { array, oneOf, string } from 'prop-types';
import cx from 'classnames';

import Legend from '../Legend/Legend';
import Text from '../Text/Text';

export const FEATURED_CONTENT_PROPS = {
  featureType: ['map', 'photo', 'quote', 'photoheader'],
  legendColors: ['#645faa', '#fdb913', '#f36f21']
};

const FeaturedContent = (props) => {
  const {
    author,
    authorCompany,
    caption,
    hyperLink,
    featureType,
    featuredImage,
    captionHeading,
    legend,
    legendColors
  } = props;
  const baseClass = 'sir-featured-content';
  const modifierClass = `${baseClass}--${featureType}`;

  if (featureType === 'map') {
    return (
      <div className={cx(baseClass, modifierClass)}>
        <div className={`${baseClass}-caption-wrapper`}>
          <Text
            className={`${baseClass}-caption-heading`}
            element="h3"
            size="xsmall"
            modifier="bold"
          >
            {captionHeading}
          </Text>
          <div className={`${baseClass}-caption-wrapper-inner`}>
            {legend ? (
              <Legend legend={legend} legendColors={legendColors} />
            ) : null}
            {caption ? (
              <Text className={`${baseClass}-caption`} size="xsmall">
                {caption}
              </Text>
            ) : null}
          </div>
        </div>
        <div className={`${baseClass}-image-wrapper`}>
          {featuredImage ? (
            <img
              className={`${baseClass}-image`}
              alt="Map image"
              src={featuredImage}
            />
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className={cx(baseClass, modifierClass)}>
      <div className={`${baseClass}-image-wrapper`}>
        {featuredImage ? (
          <img
            className={`${baseClass}-image`}
            alt="Featured image"
            src={featuredImage}
          />
        ) : null}
      </div>
      <div className={`${baseClass}-caption-wrapper`}>
        {caption && (
          <Text className={`${baseClass}-caption`} element="p">
            {caption}
          </Text>
        )}

        {hyperLink ? (
          <Text className={`${baseClass}-caption`} element="p">
            <a href={hyperLink} target="_blank" rel="noreferrer">
              Learn more{' >'}
            </a>
          </Text>
        ) : null}
        {author ? (
          <Text className={`${baseClass}-author`} modifier="bold" size="xsmall">
            {`- ${author}`}
          </Text>
        ) : null}
        {authorCompany ? (
          <Text className={`${baseClass}-author-company`} size="xsmall">
            {authorCompany}
          </Text>
        ) : null}
      </div>
    </div>
  );
};

FeaturedContent.propTypes = {
  author: string,
  authorCompany: string,
  caption: string,
  hyperLink: string,
  captionHeading: string,
  legend: string,
  legendColors: array,
  featureType: oneOf(FEATURED_CONTENT_PROPS.featureType),
  featuredImage: string
};

FeaturedContent.defaultProps = {
  featureType: 'photo',
  legendColors: FEATURED_CONTENT_PROPS.legendColors
};

export default FeaturedContent;
