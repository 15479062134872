import { array, string } from 'prop-types';

import Text from '../Text/Text';

const Legend = (props) => {
  const { legend, legendColors } = props;
  const baseClass = 'sir-legend';

  if (!legend) {
    return null;
  }

  const legendItems = legend.split('\n');

  return (
    <div className={`${baseClass}-wrapper`}>
      {legendItems.map((text, index) => (
        <div key={index} className={baseClass}>
          <div
            className={`${baseClass}-key`}
            style={{ background: legendColors[index] }}
          />
          <Text className={`${baseClass}-text`} size="xsmall">
            {text}
          </Text>
        </div>
      ))}
    </div>
  );
};

Legend.propTypes = {
  legend: string,
  legendColors: array
};

Legend.defaultProps = {
  legendColors: ['#645faa']
};

export default Legend;
