import { func, string } from 'prop-types';

import Button from '../Button/Button';
import Text from '../Text/Text';

const Navbar = (props) => {
  const { onLogout, title, username } = props;
  const heading = title || 'State Investment Reports Digital Portal';
  const baseClass = 'sir-navbar';

  return (
    <div className={baseClass}>
      <div className={`${baseClass}-container`}>
        <Text element="h1" type="display" modifier="bold" size="small">
          {heading}
        </Text>
        {username ? (
          <div className={`${baseClass}-profile`}>
            <Text className={`${baseClass}-profile-user`} size="small">
              Logged in as {username}
            </Text>
            <Button modifier="primary" onClick={onLogout}>
              Log out
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

Navbar.propTypes = {
  onLogout: func,
  title: string,
  username: string
};

export default Navbar;
