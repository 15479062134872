import { bool } from 'prop-types';

const Spinner = (props) => {
  const { show } = props;
  const baseClass = 'sir-spinner';

  if (!show) {
    return null;
  }

  return (
    <div className={`${baseClass}-overlay`}>
      <div className={baseClass}>
        <svg className={`${baseClass}-element`} viewBox="0 0 64 64">
          <rect x="29.5" width="5" height="16" rx="2.5" opacity="0./" />
          <rect
            transform="translate(20 11.21539)rotate(330)"
            x="-2.5"
            y="-8"
            width="5"
            height="16"
            rx="2.5"
            opacity="0.8"
          />
          <rect
            transform="translate(11.21539 20)rotate(300)"
            x="-2.5"
            y="-8"
            width="5"
            height="16"
            rx="2.5"
            opacity="0.74"
          />
          <rect
            transform="translate(8 32)rotate(270)"
            x="-2.5"
            y="-8"
            width="5"
            height="16"
            rx="2.5"
            opacity="0.68"
          />
          <rect
            transform="translate(11.21539 44)rotate(240)"
            x="-2.5"
            y="-8"
            width="5"
            height="16"
            rx="2.5"
            opacity="0.62"
          />
          <rect
            transform="translate(20 52.78461)rotate(210)"
            x="-2.5"
            y="-8"
            width="5"
            height="16"
            rx="2.5"
            opacity="0.56"
          />
          <rect x="29.5" y="48" width="5" height="16" rx="2.5" opacity="0.5" />
          <rect
            transform="translate(44 52.78461)rotate(150)"
            x="-2.5"
            y="-8"
            width="5"
            height="16"
            rx="2.5"
            opacity="0.44"
          />
          <rect
            transform="translate(52.78461 44)rotate(120)"
            x="-2.5"
            y="-8"
            width="5"
            height="16"
            rx="2.5"
            opacity="0.38"
          />
          <rect
            transform="translate(56 32)rotate(90)"
            x="-2.5"
            y="-8"
            width="5"
            height="16"
            rx="2.5"
            opacity="0.32"
          />
          <rect
            transform="translate(52.78461 20)rotate(60)"
            x="-2.5"
            y="-8"
            width="5"
            height="16"
            rx="2.5"
            opacity="0.26"
          />
          <rect
            transform="translate(44 11.21539)rotate(30)"
            x="-2.5"
            y="-8"
            width="5"
            height="16"
            rx="2.5"
            opacity="0.2"
          />
        </svg>
      </div>
    </div>
  );
};

Spinner.propTypes = {
  show: bool
};

Spinner.defaultProps = {
  show: true
};

export default Spinner;
