import { node, string } from 'prop-types';
import Heading from '../Heading/Heading';

const StatCardGrid = (props) => {
  const { category, children } = props;
  const baseClass = 'sir-stat-card-grid';

  return (
    <div className={`${baseClass}-container`}>
      <Heading element="h3" size="small" withDivider>
        {category}
      </Heading>
      <div className={baseClass}>{children}</div>
    </div>
  );
};

StatCardGrid.propTypes = {
  category: string.isRequired,
  children: node
};

export default StatCardGrid;
