import { Fragment } from 'react';
import { Link, Text } from '@comcast-sir/ui-library';

export const fixLink = content => {
  if (!content) {
    return '';
  }

  return content.replace(/\\_/g, '_');
};

export const replaceLink = (content, index) => {
  const match = content && content.match(/\[(?<title>.+)\]\((?<url>.+)\)/);
  const key = index;
  const size = typeof key === 'number' ? 'xsmall' : index;

  if (!match || !match.groups) {
    return (
      <Text key={key} element="p" size={size} style={{ fontWeight: 500 }}>
        {content}
      </Text>
    );
  }

  const { title, url } = match.groups;
  let external = url.startsWith('https') || url.startsWith('mailto:');
  const to = external ? url : url.replace('http://', '');
  const splitContent = content.split(match[0]);
  //reset external for mailto links
  external = url.startsWith('https');

  if (match.index > 0) {
    // Replaces link inline with content
    return (
      <Text key={key} size={size}>
        {splitContent[0]}
        <Link key={key} to={to} external={external} size={size}>
          {title}
        </Link>
        {splitContent[1]}
      </Text>
    );
  }

  return (
    <Link key={key} to={to} external={external} size="xsmall">
      {title}
    </Link>
  );
};

export const replaceLinks = content => {
  if (!content) {
    return null;
  }

  return content.split('\n').map(replaceLink);
};

export const withHeadingLink = (url, text) => {
  if (!url) {
    return (
      <Text element="h3" modifier="bold">
        {text}
      </Text>
    );
  }

  const external = !url.startsWith('/');

  return (
    <Link to={url} external={external}>
      {text}
    </Link>
  );
};

export const getFilename = report => {
  if (report?.title) {
    const trimmedFileName = report.title.replace(/\s/g, '');
    return `${trimmedFileName}`;
  }
  const prependedNameValue = report?.cityName || report?.stateName;
  const trimmedFileName = prependedNameValue
    .replace(/\s/g, '')
    .concat('Report.pdf');

  if (!prependedNameValue) {
    return '';
  }
  return `${trimmedFileName}`;
};

export const replaceBoldText = content => {
  const match = content && content.match(/\*\*(?<bold>.+)\*\*/);

  if (!match) {
    return content;
  }

  // Change text wrapped with ** to bold
  const split = content.split('**');

  return (
    <Fragment>
      {split.map((text, index) =>
        index % 2 ? (
          <Text key={index} modifier="bold">
            {text}
          </Text>
        ) : (
          text
        )
      )}
    </Fragment>
  );
};

export const getPrintDescription = content => {
  const key = 'print-report-description';

  if (!content || !content[key]) {
    return [];
  }

  const lines = content[key].split('\n');

  return (
    <div className="sir-print-description">
      {lines.map((line, index) => (
        <Text element="p" key={index}>
          {replaceBoldText(line)}
        </Text>
      ))}
    </div>
  );
};
