import { Fragment, useEffect } from 'react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { object } from 'prop-types';
import { ActionCard, Spinner } from '@comcast-sir/ui-library';

import { getFilename, getModifiedDate } from '../../helpers';
import { useSavedReportActions } from '../../hooks';

import ReportDocument from '../ReportDocument/ReportDocument';

const SavedReport = props => {
  const { actions, report } = props;
  const [savedActions, saved] = useSavedReportActions(report, actions);
  const timestamp = getModifiedDate(report);
  const subtitle = `Last modified: ${timestamp}`;
  const reportId = report.id;
  const baseClass = 'sir-saved-report';

  useEffect(() => {
    const downloadReport = async doc => {
      const filename = getFilename(doc);
      const blob = await pdf(
        <ReportDocument report={doc} stats={doc.stats} />
      ).toBlob();

      saveAs(blob, filename);
    };

    if (saved.report.id) {
      downloadReport(saved.report);
    }
  }, [saved.report]);

  const getActions = () => {
    return [
      {
        label: 'Download report',
        onClick: savedActions.download.bind(null, reportId)
      },
      {
        label: 'Edit report',
        onClick: savedActions.edit
      },
      {
        label: 'Rename report',
        onClick: savedActions.rename
      },
      {
        label: 'Share internally via link',
        onClick: actions.shareLink.bind(null, reportId)
      },
      {
        label: 'Delete report',
        modifier: 'danger',
        onClick: actions.confirmDelete.bind(null, reportId)
      }
    ];
  };

  return (
    <Fragment>
      <ActionCard
        className={baseClass}
        id={reportId}
        title={report.title}
        to={`/report/${reportId}`}
        subtitle={subtitle}
        actions={getActions()}
        editable={saved.editable}
        onSubmit={savedActions.submitRename}
        tag={report?.cityName ? 'City Report' : 'State Report'}
        tagColor={report?.cityName ? 'purple' : 'blue'}
      />
      {saved.reportId && !saved.loaded ? <Spinner /> : null}
    </Fragment>
  );
};

SavedReport.propTypes = {
  actions: object,
  report: object
};

export default SavedReport;
