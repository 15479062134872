import cx from 'classnames';
import { bool, func, node, number, oneOfType, string } from 'prop-types';

import Button from '../Button/Button';
import Card from '../Card/Card';
import Checkbox from '../Checkbox/Checkbox';
import Tag from '../Tag/Tag';
import Text from '../Text/Text';
import Tooltip from '../Tooltip/Tooltip';

const StatCard = (props) => {
  const {
    checked,
    children,
    disabled,
    definition,
    figure,
    id,
    label,
    footnote,
    onSelect,
    order,
    warning
  } = props;
  const baseClass = 'sir-stat-card';
  const selectedClass = checked && `${baseClass}--selected`;
  const headerClass = `${baseClass}-header`;
  const tagId = `stat-${id}-tag`;
  const warningContent = warning ? warning.split('\n') : [];
  const warningTitle =
    warningContent.length > 1 ? warningContent[0] : undefined;
  const warningMessage =
    warningContent.length > 1 ? warningContent[1] : warning;

  return (
    <Card className={cx(baseClass, selectedClass)} withShadow={checked}>
      <header className={headerClass}>
        {definition ? (
          <Tooltip
            id={tagId}
            modifier="neutral"
            trigger={
              <Tag size="xsmall">
                <Button modifier="link" size="xsmall">
                  {label}
                </Button>
              </Tag>
            }
          >
            {definition}
          </Tooltip>
        ) : (
          <Tag id={tagId} size="xsmall">
            {label}
          </Tag>
        )}
        <div className={`${headerClass}-controls`}>
          {warning ? (
            <Tooltip
              id={`${tagId}-warning`}
              modifier="warning"
              title={warningTitle}
              trigger={
                <Button
                  iconProps={{ name: 'warning', title: 'Warning' }}
                  iconOnly
                />
              }
            >
              {warningMessage}
            </Tooltip>
          ) : null}
          <Checkbox
            id={`stat-${id}`}
            checked={checked}
            disabled={disabled}
            aria-labelledby={tagId}
            label={checked ? order : ''}
            onChange={onSelect}
            name="stat"
            value={id}
          />
        </div>
      </header>
      <Text className={`${baseClass}-figure`} type="display" size="small">
        {figure}
      </Text>
      {children}
      {footnote ? (
        <Text className={`${baseClass}-footnote`} type="display" size="tiny">
          {footnote}
        </Text>
      ) : null}
    </Card>
  );
};

StatCard.propTypes = {
  checked: bool,
  children: node,
  definition: node,
  disabled: bool,
  figure: string.isRequired,
  id: oneOfType([number, string]).isRequired,
  label: string,
  footnote: string,
  onSelect: func.isRequired,
  order: oneOfType([number, string]),
  warning: string
};

StatCard.defaultProps = {
  checked: false,
  disabled: false
};

export default StatCard;
