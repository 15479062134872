import { Fragment, useEffect, useRef } from 'react';
import { bool, func, node, string } from 'prop-types';
import cx from 'classnames';

import Button from '../Button/Button';
import Card from '../Card/Card';
import Heading from '../Heading/Heading';

const Modal = (props) => {
  const { children, className, id, onClose, show, title, ...otherProps } =
    props;
  const baseClass = 'sir-modal';
  const cardClass = `${baseClass}-card`;
  const visibilityClass = show && `${baseClass}--show`;
  const btnRef = useRef();
  const handleEscape = (event) => {
    if (event.key === 'Escape') {
      onClose(event);
    }
  };

  useEffect(() => {
    const btn = btnRef.current;

    if (show && btn) {
      btn.focus();

      document.addEventListener('keydown', handleEscape);

      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    }
  }, [show]);

  return (
    <Fragment>
      <div
        className={cx(baseClass, className, visibilityClass)}
        id={id}
        role="dialog"
        aria-labelledby="modal-title"
        aria-hidden={!show}
        {...otherProps}
      >
        <Card withShadow className={cardClass} role="document">
          <header className={`${cardClass}-header`}>
            <Button
              iconProps={{
                name: 'close',
                modifier: 'gray',
                title: 'Close modal'
              }}
              iconOnly
              onClick={onClose}
              ref={btnRef}
            />
          </header>
          <div className={`${cardClass}-body`}>
            <Heading id="modal-title" element="h2" size="small">
              {title}
            </Heading>
            {children}
          </div>
        </Card>
      </div>
      <div
        className={cx(`${baseClass}-overlay`, visibilityClass)}
        tabIndex={-1}
      ></div>
    </Fragment>
  );
};

Modal.propTypes = {
  children: node,
  className: string,
  id: string.isRequired,
  onClose: func.isRequired,
  show: bool,
  title: string
};

Modal.defaultProps = {
  show: false
};

export default Modal;
