import { saveAs } from 'file-saver';
import { bool, func, object } from 'prop-types';
import {
  Button,
  Heading,
  Icon,
  Link,
  SkeletonLoader,
  Text
} from '@comcast-sir/ui-library';

import { fixLink, getFilename, replaceLink } from '../../helpers';

const ReportExportContent = props => {
  const { content, globalContent, loaded, report, user, reportPDF } = props;
  const { givenname, surname, mail: userEmail } = user.idTokenClaims;
  const userFirstAndLastName = `${givenname} ${surname}`;
  const baseClass = 'sir-report-export-pdf';
  const hasError = report.error || false;
  const iconName = hasError ? 'error' : 'success';
  const subHeadingTitle = report?.cityName
    ? content['subheading-2']
    : content.subheading;
  const description = hasError
    ? content.error
    : replaceLink(content.description);

  const generateDocument = async () => {
    const filename = getFilename(report);
    const blob = await reportPDF.toBlob();
    saveAs(blob, filename);
  };

  const email = fixLink(globalContent.email);
  const emailLink = `mailto:${email}?subject=Shared Report not found&body=%0D%0AReport reference ID: ${report.errorId}`;

  if (!loaded && !hasError) {
    return (
      <div className={`${baseClass}-content`}>
        <SkeletonLoader width="128px" height="28px" />
        <SkeletonLoader width="322px" height="24px" />
        <SkeletonLoader width="300px" height="24px" />
        <SkeletonLoader width="335px" height="16px" />
        <SkeletonLoader width="335px" height="16px" />
        <SkeletonLoader width="335px" height="16px" />
        <SkeletonLoader width="285px" height="16px" />
      </div>
    );
  }

  return (
    <div className={`${baseClass}-content`}>
      <div className="sir-icon-label">
        <Icon name={iconName} title="" />
        <Text type="display" size="small">
          {hasError ? content['error-heading'] : 'Success!'}
        </Text>
      </div>
      {!hasError && (
        <Heading element="h3" size="small">
          {subHeadingTitle}
        </Heading>
      )}
      <Text element="p">{description}</Text>
      <div className={`${baseClass}-content-controls`}>
        {!hasError ? (
          <Button modifier="primary" size="large" onClick={generateDocument}>
            {content['download-action']}
          </Button>
        ) : (
          <Link
            to={emailLink}
            modifier="primary"
            size="large"
            iconProps={{ name: 'external', title: 'Mail link' }}
            external
          >
            {content['error-action']}
          </Link>
        )}
        {/* commenting out the previous functionality of a "Print Report" button that opened the print modal */}
        {/* {!hasError && (
          <Button modifier="secondary" size="large" onClick={showModal}>
            {content['print-action']}
          </Button>
        )} */}
        {!hasError && (
          <Link
            style={{ textAlign: 'center' }}
            to={`/request-printed-receipt?&prefill_First+and+Last+Name=${userFirstAndLastName}&prefill_Email=${userEmail}&prefill_Reference+ID=${report.id}`}
            modifier="secondary"
            size="large"
            external
          >
            Request Printed Reports
          </Link>
        )}
      </div>
    </div>
  );
};

ReportExportContent.propTypes = {
  content: object.isRequired,
  globalContent: object.isRequired,
  loaded: bool.isRequired,
  report: object,
  showModal: func.isRequired,
  user: object.isRequired,
  reportPDF: object
};

export default ReportExportContent;
