import { bool, node, string } from 'prop-types';
import cx from 'classnames';

const SkeletonLoader = (props) => {
  const { children, className, loaded, width, height, rounded } = props;
  const baseClass = 'sir-skeleton-loader';
  const roundedClass = rounded && `${baseClass}--rounded`;

  if (loaded) {
    return children || null;
  }

  return (
    <div
      className={cx(baseClass, roundedClass, className)}
      style={{ width, height }}
    ></div>
  );
};

SkeletonLoader.propTypes = {
  children: node,
  className: string,
  loaded: bool,
  width: string,
  height: string,
  rounded: bool
};

SkeletonLoader.defualtProps = {
  loaded: false,
  width: '100%',
  height: '100%',
  rounded: false
};

export default SkeletonLoader;
