import { Children } from 'react';
import { node, oneOf, string } from 'prop-types';
import cx from 'classnames';

export const TEXT_PROPS = {
  element: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'small', 'span', 'strong'],
  modifier: ['default', 'bold', 'italic'],
  size: ['large', 'medium', 'small', 'xsmall', 'tiny'],
  type: ['accessible', 'body', 'display']
};

const Text = (props) => {
  const {
    children,
    className,
    element: Element,
    modifier,
    size,
    type,
    ...otherProps
  } = props;
  const baseClass = 'sir-type';
  const typeClass = `${baseClass}-${type}`;
  const sizeClass = `${typeClass}--${size}`;
  const modifierClass = modifier ? `${baseClass}-style--${modifier}` : '';

  if (!Children.count(children)) {
    return null;
  }

  return (
    <Element
      className={cx(typeClass, sizeClass, modifierClass, className)}
      {...otherProps}
    >
      {children}
    </Element>
  );
};

Text.propTypes = {
  children: node,
  className: string,
  element: oneOf(TEXT_PROPS.element),
  modifier: oneOf(TEXT_PROPS.modifier),
  size: oneOf(TEXT_PROPS.size),
  type: oneOf(TEXT_PROPS.type)
};

Text.defaultProps = {
  element: 'span',
  size: 'medium',
  type: 'body'
};

export default Text;
