import { createContext, useContext, useEffect, useState } from 'react';
import { useFetch } from '../browser';

export const ContentContext = createContext();

export const useContent = () => {
  const [endpoint, setEndpoint] = useState(null);
  const [response] = useFetch(endpoint);

  useEffect(() => {
    setEndpoint({
      url: '/api/content'
    });
  }, []);

  return response;
};

export const useSignedUrl = resourceName => {
  const [endpoint, setEndpoint] = useState(null);
  const [response] = useFetch(endpoint);

  useEffect(() => {
    setEndpoint({
      url: `/api/signed-url/${resourceName}`
    });
  }, {});
  return response;
};

export const useKeyResources = resourceType => {
  const [endpoint, setEndpoint] = useState(null);
  const [response] = useFetch(endpoint);

  useEffect(() => {
    setEndpoint({
      url: `/api/key-resources/${resourceType}`
    });
  }, []);
  return response;
};

export const useContentByScreen = screen => {
  const content = useContext(ContentContext);

  if (content && Object.prototype.hasOwnProperty.call(content, screen)) {
    return content[screen];
  }

  return content || {};
};
