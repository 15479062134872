import { func, string } from 'prop-types';
import { useEffect, useRef } from 'react';

import Button from '../Button/Button';
import Input from '../Input/Input';

const EditForm = (props) => {
  const { id, initialValue, inputLabel, onSubmit, submitLabel } = props;
  const baseClass = 'sir-edit-form';
  const inputRef = useRef(null);

  useEffect(() => {
    const input = inputRef.current;

    if (input) {
      input.focus();
    }
  }, [inputRef.current]);

  return (
    <form className={baseClass} onSubmit={onSubmit}>
      <Input
        id={id}
        defaultValue={initialValue}
        label={inputLabel}
        hideLabel
        size="medium"
        ref={inputRef}
        required
      />
      <Button modifier="primary" type="submit">
        {submitLabel}
      </Button>
    </form>
  );
};

EditForm.propTypes = {
  id: string.isRequired,
  initialValue: string,
  inputLabel: string,
  onSubmit: func.isRequired,
  submitLabel: string
};

EditForm.defaultProps = {
  inputLabel: 'Edit',
  submitLabel: 'Save'
};

export default EditForm;
