const RequestPrintedReceipt = () => {
  const url = window.location.href;
  const urlSplited = url.split('/');
  const pdfSplited = urlSplited[urlSplited.length - 1].split('?');
  const iframeLink = `https://airtable.com/embed/shrDKNSyhr36AENom?${pdfSplited[1].slice(
    1
  )}`;

  return (
    <div>
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
          zIndex: '10'
        }}
      >
        <iframe
          src={iframeLink}
          frameBorder="0"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
  );
};

export default RequestPrintedReceipt;
