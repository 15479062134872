import { bool, string } from 'prop-types';
import cx from 'classnames';

const Toggle = (props) => {
  const { hideLabel, id, label, ...otherProps } = props;
  const labelClass = 'sir-form-field-label';
  const labelTextClass = `${labelClass}-text`;
  const hiddenClass = hideLabel ? 'sir-accessibly-hidden' : '';

  return (
    <div className="sir-form-field">
      <input
        className="sir-form-field-toggle"
        id={id}
        type="checkbox"
        {...otherProps}
      />
      <label className={labelClass} htmlFor={id}>
        <span className={cx(labelTextClass, hiddenClass)}>{label}</span>
      </label>
    </div>
  );
};

Toggle.propTypes = {
  /**
   * toggle's on/off state
   */
  checked: bool,

  /**
   * visually hide text label
   */
  hideLabel: bool,

  /**
   * toggle ID and label htmlFor value
   */
  id: string.isRequired,

  /**
   * label text
   */
  label: string.isRequired
};

Toggle.defaultProps = {
  checked: false,
  hideLabel: false
};

export default Toggle;
