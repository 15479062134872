import { bool, func, node, string } from 'prop-types';
import cx from 'classnames';

import Button from '../Button/Button';
import Card from '../Card/Card';
import Text from '../Text/Text';
import ProgressIndicator from '../ProgressIndicator/ProgressIndicator';

const Accordion = (props) => {
  const {
    checked,
    children,
    className,
    expanded,
    id,
    label,
    onClick,
    trigger
  } = props;
  const baseClass = 'sir-accordion';
  const idClass = `${baseClass}-${id}`;
  const headerClass = `${baseClass}-header`;
  const contentClass = `${headerClass}-content`;
  const expandedClass = expanded ? `${baseClass}--expanded` : '';
  const iconName = expanded ? 'chevron-up' : 'chevron-down';
  const accordionId = `accordion-${id}`;
  const triggerId = `${accordionId}-trigger`;

  return (
    <Card
      className={cx(baseClass, idClass, expandedClass, className)}
      withShadow={expanded}
    >
      <header className={headerClass}>
        <div className={contentClass}>
          <ProgressIndicator checked={checked} label={label} />
          <div id={triggerId} className={`${contentClass}-trigger`}>
            <Text size="small" modifier="medium">
              {label}
            </Text>
            {trigger || null}
          </div>
        </div>
        <div className={`${baseClass}-controls`}>
          {children ? (
            <Button
              iconProps={{
                name: iconName,
                modifier: 'gray',
                title: 'Toggle accordion'
              }}
              iconOnly
              onClick={onClick}
              aria-controls={accordionId}
            />
          ) : null}
        </div>
      </header>
      <section
        id={accordionId}
        className={`${baseClass}-body`}
        aria-expanded={expanded}
        aria-labelledby={triggerId}
      >
        {children}
      </section>
    </Card>
  );
};

Accordion.propTypes = {
  checked: bool,
  children: node,
  className: string,
  expanded: bool,
  id: string.isRequired,
  label: string.isRequired,
  onClick: func,
  trigger: node
};

Accordion.defaultProps = {
  checked: false,
  expanded: false
};

export default Accordion;
