import { bool, string } from 'prop-types';
import cx from 'classnames';

import Icon from 'components/Icon/Icon';

const ProgressIndicator = (props) => {
  const { checked, label } = props;
  const baseClass = 'sir-progress-indicator';
  const completedClass = checked ? `${baseClass}--completed` : '';

  return (
    <div className={cx(baseClass, completedClass)}>
      <label className="sir-accessibly-hidden">
        <span>{label}</span>
        <input type="checkbox" checked={checked} readOnly />
      </label>
      <div className="sir-icon-wrapper">
        {checked ? <Icon name="success" title="Step complete" /> : null}
      </div>
    </div>
  );
};

ProgressIndicator.propTypes = {
  checked: bool,
  label: string.isRequired
};

ProgressIndicator.defaultProps = {
  checked: false
};

export default ProgressIndicator;
