import { array, object, bool } from 'prop-types';
import { useHistory } from 'react-router';
import {
  Button,
  Heading,
  Input,
  ReportPreview,
  Text
} from '@comcast-sir/ui-library';

import frame from '../../assets/images/frame.png';
import logo from '../../assets/images/logo-comcast.png';
import quote from '../../assets/images/quote.png';

import { useContentByScreen, useReport, useReportNickname } from '../../hooks';
import { CITY_ACCEPTABLE_DATA_POINTS, MAX_DATA_POINTS } from '../../constants';

const ReportReview = props => {
  const { report, stats, photoheader, showBorder } = props;
  const content = useContentByScreen('report-builder');
  const globalContent = useContentByScreen('global');
  const [nickname, onChange] = useReportNickname(report);
  const savedReport = useReport();
  const history = useHistory();
  const featuredImage = (report && report.image) || quote;
  const placeholder = report && report.title;
  const baseClass = 'sir-report-review';
  const handleCancel = () => {
    history.goBack();
  };

  const validStatsCount = report?.cityName
    ? !CITY_ACCEPTABLE_DATA_POINTS.includes(stats.length)
    : stats.length !== MAX_DATA_POINTS;

  if (!report || validStatsCount) {
    return null;
  }

  return (
    <div className={baseClass}>
      <div className={`${baseClass}-content`}>
        <Heading size="small">{content['review-heading']}</Heading>
        <Text element="p">{content['review-description']}</Text>
        <Text element="p">{content['nickname-description']}</Text>
        <Input
          id="report-name"
          name="nickname"
          label="Custom Report Nickname (Optional)"
          placeholder={placeholder}
          onChange={onChange}
          value={nickname}
        />
        <div
          className={`${baseClass}-preview ${baseClass}-preview--show-on-mobile`}
        >
          <ReportPreview
            mini
            content={report}
            featureType={report.featureType}
            featuredImage={featuredImage}
            legend={report.legend}
            legendColors={report.legendColors}
            stats={stats}
            frame={frame}
            logo={logo}
            qrCode={report.qrCodeUrl}
            photoHeader={photoheader}
            showBorder={showBorder}
            footerOverview={
              report?.cityName ? globalContent['city-overview'] : ''
            }
          />
          <div className="sir-mobile-disclaimer sir-type-body--small">
            Note: Custom report preview on mobile devices for reference purposes
            only. PDF format will be sized to 8.5"x11".
          </div>
        </div>
        <div className={`${baseClass}-content-controls`}>
          <Button modifier="primary" size="large" type="submit">
            {savedReport ? content['edit-action'] : content['create-action']}
          </Button>
          <Button modifier="secondary" size="large" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </div>
      <div
        className={`${baseClass}-preview ${baseClass}-preview--hide-on-mobile`}
      >
        <ReportPreview
          mini
          content={report}
          featureType={report.featureType}
          featuredImage={featuredImage}
          legend={report.legend}
          legendColors={report.legendColors}
          stats={stats}
          frame={frame}
          logo={logo}
          qrCode={report.qrCodeUrl}
          photoHeader={photoheader}
          showBorder={showBorder}
          footerOverview={
            report?.cityName ? globalContent['city-overview'] : ''
          }
        />
      </div>
    </div>
  );
};

ReportReview.propTypes = {
  report: object,
  stats: array.isRequired,
  photoheader: object,
  showBorder: bool
};

export default ReportReview;
