import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getSavedStateAbbr, getStateName } from '../../helpers';
import { useAccountName, useFetch } from '../browser';
import { StatesContext } from '../report';

export const useSharedReport = report => {
  const [endpoint, setEndpoint] = useState(null);
  const [response, request] = useFetch(endpoint);
  const username = useAccountName();
  const history = useHistory();

  useEffect(() => {
    if (!report || !report.id) {
      return;
    }

    if (response || request.loading) {
      if (response.id) {
        // Redirect to newly copied report
        history.push(`/report/${response.id}`);
      }

      return;
    }

    if (report.creator && report.creator !== username) {
      // Create new report
      const params = {
        url: `/api/reports/${report.stateAbbr}`,
        method: 'POST',
        body: JSON.stringify({
          title: `${report.stateName} Report`,
          stats: report.stats,
          featureId: report.featureId,
          featureType: report.featureType
        })
      };

      setEndpoint(params);
    }
  }, [report, response]);

  return response;
};

export const useSavedReport = savedId => {
  const [report, setReport] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [reportEndpoint, setEndpoint] = useState(null);
  const [response, request, clearResponse] = useFetch(reportEndpoint);
  const { id } = useParams();
  const reportId = id || savedId;

  useSharedReport(report);

  useEffect(() => {
    if (reportId && !report.id && !request.loading) {
      setEndpoint({
        url: `/api/report/${reportId}`
      });
    }
  }, [report, request.loading, reportId]);

  useEffect(() => {
    if (response && !reportId) {
      // Reset saved report data and endpoint
      setEndpoint(null);
      setLoaded(false);
      setReport({});
      clearResponse();
    }
  }, [reportId, response]);

  useEffect(() => {
    if (request.error) {
      setEndpoint(null);
      setReport({ errorId: reportId, error: request.error });
      clearResponse();
    }
  }, [request.error]);

  useEffect(() => {
    if (response && !report.id) {
      const { feature, featureType } = response;

      if (featureType !== 'quote') {
        setReport({
          ...response,
          caption: feature.caption,
          captionHeading: feature.captionHeading,
          image: feature.image.url,
          legend: feature.legend,
          legendColors: feature.legendColors,
          hyperLink: feature.hyperlink
        });
      } else {
        setReport({
          ...response,
          author: feature.author,
          authorCompany: feature.authorCompany,
          caption: feature.quote
        });
      }
    }

    if (response && report.id) {
      setLoaded(true);
    }
  }, [response, report]);

  return [report, loaded];
};

export const useExportReportActions = report => {
  const history = useHistory();
  const states = useContext(StatesContext);
  const stateAbbr = getSavedStateAbbr(report);
  const stateName = getStateName(states, stateAbbr);

  const edit = () => {
    history.push({
      pathname: '/report',
      state: {
        abbr: stateAbbr,
        name: stateName,
        city: report?.cityName,
        report
      }
    });
  };

  const actions = {
    edit
  };
  return [actions];
};

export const useSavedReportActions = (report, commonActions) => {
  const [editable, setEditable] = useState(false);
  const [renameEndpoint, setEndpoint] = useState('');
  const [reportId, setReportId] = useState(null);
  const [savedReport, loaded] = useSavedReport(reportId);
  const [response] = useFetch(renameEndpoint);
  const history = useHistory();
  const states = useContext(StatesContext);
  const stateAbbr = getSavedStateAbbr(report);
  const stateName = getStateName(states, stateAbbr);

  const edit = () => {
    history.push({
      pathname: '/report',
      state: {
        abbr: stateAbbr,
        name: stateName,
        city: report?.cityName,
        report
      }
    });
  };
  const rename = () => {
    setEditable(!editable);
  };

  const submitRename = event => {
    const form = event.target;
    const value = form.elements[0].value.trim();

    event.preventDefault();

    if (value.length && value !== report.title) {
      const params = {
        url: `/api/report/${report.id}`,
        method: 'PATCH',
        body: JSON.stringify({
          title: value
        })
      };

      setEndpoint(params);
    } else {
      setEditable(false);
    }
  };

  useEffect(() => {
    if (response) {
      setEditable(false);
      setEndpoint(null);

      if (commonActions) {
        commonActions.updateReport(report, response.title);
      }
    }
  }, [response]);

  useEffect(() => {
    if (savedReport) {
      // Reset current report ID
      setReportId(null);
    }
  }, [savedReport]);

  const data = {
    editable,
    loaded,
    report: savedReport,
    reportId
  };

  const actions = {
    edit,
    rename,
    submitRename,
    download: setReportId
  };
  return [actions, data];
};

export const useSavedReports = () => {
  const [savedReports, setSavedReports] = useState([]);
  const [endpoint, setEndpoint] = useState(null);
  const [response, request] = useFetch(endpoint, []);
  const [deleteEndpoint, setDeleteEndpoint] = useState('');
  const [activeReportId, setActiveReportId] = useState('');
  const [currentSort, setCurrentSort] = useState('modifiedDate:dsc');
  const [showShareModal, setShareModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const shareLink = id => {
    setActiveReportId(id);
    setShareModal(true);
  };

  useFetch(deleteEndpoint);

  const confirmDelete = id => {
    setActiveReportId(id);
    setDeleteModal(true);
  };

  const deleteReport = reportId => {
    setDeleteEndpoint({
      url: `/api/report/${reportId}`,
      method: 'DELETE'
    });

    const updatedReports = savedReports.filter(
      report => report.id !== reportId
    );

    setSavedReports(updatedReports);
    setDeleteModal(false);
  };

  const sort = event => {
    const { value } = event.target;

    setCurrentSort(value);
  };

  const sortReports = reports => {
    const [sortProp, sortOrder] = currentSort.split(':');
    const sorted = reports.slice(0).sort((a, b) => {
      const aProp = a[sortProp];
      const bProp = b[sortProp];

      return aProp.localeCompare(bProp);
    });

    if (sortOrder === 'dsc') {
      sorted.reverse();
    }

    return sorted;
  };

  const updateReport = (report, title) => {
    const updatedReports = savedReports.map(savedReport => {
      // Update report with new title
      return savedReport.id === report.id
        ? { ...savedReport, title }
        : savedReport;
    });

    setSavedReports(updatedReports);
  };

  useEffect(() => {
    if (!endpoint) {
      const params = {
        url: '/api/reports'
      };

      setEndpoint(params);
    }
  });

  useEffect(() => {
    if (response && response.length) {
      const reports = sortReports(response);

      setSavedReports(reports);
    }
  }, [response]);

  useEffect(() => {
    if (savedReports.length) {
      const sorted = sortReports(savedReports);

      setSavedReports(sorted);
    }
  }, [currentSort]);

  const data = {
    currentSort,
    loading: request.loading,
    reportId: activeReportId,
    deleteModal: showDeleteModal,
    shareModal: showShareModal
  };

  const actions = {
    closeDeleteModal: () => setDeleteModal(false),
    closeShareModal: () => setShareModal(false),
    confirmDelete,
    deleteReport,
    shareLink,
    sort,
    updateReport
  };

  return [savedReports, data, actions];
};
