import { arrayOf, bool, node, oneOf, shape, string } from 'prop-types';
import cx from 'classnames';
import Icon from '../Icon/Icon';

export const SELECT_PROPS = {
  size: ['large', 'medium', 'small']
};

const Select = (props) => {
  const { hideLabel, id, label, size, children, options, ...otherProps } =
    props;

  const baseClass = 'sir-form-field-select';
  const sizeClass = `${baseClass}--${size}`;
  const elementClass = `${baseClass}-element`;
  const hiddenClass = hideLabel && 'sir-accessibly-hidden';

  const renderSelect = () => {
    return (
      <select id={id} className={elementClass} {...otherProps}>
        {options && options.length
          ? options.map((opt) => (
              <option key={opt.id} value={opt.value}>
                {opt.label}
              </option>
            ))
          : children}
      </select>
    );
  };

  return (
    <div className="sir-form-field">
      <label htmlFor={id} className={cx('sir-form-field-label', hiddenClass)}>
        {label}
      </label>
      <div className={cx(baseClass, sizeClass)}>
        {renderSelect()}
        <div className="sir-icon-wrapper">
          <Icon name="chevron-down" modifier="gray" title="" />
        </div>
      </div>
    </div>
  );
};

const selectOption = {
  /**
   * unique ID of the select option
   */
  id: string,

  /**
   * the option's displayed text
   */
  label: string,

  /**
   * the option's value to be submitted with the form
   */
  value: string
};

Select.propTypes = {
  /**
   * accessibly hide input label
   */
  hideLabel: bool,
  /**
   * select ID and label htmlFor value
   */
  id: string.isRequired,

  /**
   * label text
   */
  label: string.isRequired,

  /**
   * select field size modifier
   */
  size: oneOf(SELECT_PROPS.size),

  /**
   * select field's current value
   */
  value: string,

  /**
   * an array of options objects to populate the select field
   */
  options: arrayOf(shape(selectOption)),

  /**
   * <option>s can be provided as children instead of an object for more semantic control
   * the options prop takes priority over children
   */
  children: node
};

Select.defaultProps = {
  hideLabel: false,
  size: 'large'
};

export default Select;
