import { array, func, string, bool, object, number } from 'prop-types';
import {
  Accordion,
  Button,
  DirectInvestment,
  Tag,
  Text
} from '@comcast-sir/ui-library';

import frame from '../../assets/images/frame.png';

import { useContentByScreen, useSelectedStats } from '../../hooks';
import StatCategory from '../StatCategory/StatCategory';
import { CITY_ACCEPTABLE_DATA_POINTS, MAX_DATA_POINTS } from '../../constants';

const DisclaimerAndDirectInvestment = ({
  content,
  directInvestment,
  displayDisclaimer,
  shouldDisplay
}) => {
  if (!shouldDisplay) {
    return null;
  }
  return (
    <>
      <Text element="p">{content['data-description']}</Text>
      {displayDisclaimer && (
        <Text element="p" style={{ fontStyle: 'italic' }}>
          {content['data-description-lower']}
        </Text>
      )}
      <DirectInvestment content={directInvestment} frame={frame} />
    </>
  );
};

DisclaimerAndDirectInvestment.propTypes = {
  content: object.isRequired,
  directInvestment: string,
  displayDisclaimer: bool,
  shouldDisplay: bool.isRequired
};

const SelectDataAccordion = props => {
  const {
    directInvestment,
    displayDisclaimer,
    stats,
    onConfirm,
    selectedState,
    stepLevel
  } = props;
  const [data, actions] = useSelectedStats(stats, onConfirm);
  const content = useContentByScreen('report-builder');
  const loaded = Boolean(stats.length);
  const selectedCount = data.selected.length;
  const maxDataPointLimit = selectedCount === MAX_DATA_POINTS;
  const isValidCityDataPointConfiguration =
    CITY_ACCEPTABLE_DATA_POINTS.includes(selectedCount);
  const allChecked = selectedState?.city
    ? isValidCityDataPointConfiguration
    : maxDataPointLimit;
  const baseClass = 'sir-accordion-content-data';

  return (
    <Accordion
      checked={allChecked && data.confirmed}
      expanded={data.expanded}
      id="data-selection"
      label={`Step ${stepLevel}: Select Custom Data`}
      onClick={actions.toggle}
      trigger={
        <div
          key="data-trigger"
          className="sir-accordion-content-data-selection"
        >
          <Text size="small" type="display" style={{ fontWeight: 500 }}>
            Data Points
          </Text>
          <Tag>
            {selectedState?.city ? selectedCount : `${selectedCount}/6`}
          </Tag>
        </div>
      }
    >
      <section className={baseClass}>
        <header className={`${baseClass}-header`}>
          <DisclaimerAndDirectInvestment
            directInvestment={directInvestment}
            displayDisclaimer={displayDisclaimer}
            content={content}
            shouldDisplay={Boolean(selectedState?.name)}
          />
          <Text element="span" modifier="bold">
            {selectedState?.city
              ? content['data-heading-2']
              : content['data-heading']}
          </Text>
        </header>
        <div className={`${baseClass}-body`}>
          {data.categories.map(category => (
            <StatCategory
              key={category.name}
              category={category}
              disabled={selectedState?.city ? maxDataPointLimit : allChecked}
              loaded={loaded}
              onSelect={actions.selectStat}
            />
          ))}
        </div>
        <footer className="sir-report-builder-footer">
          <Button
            disabled={!allChecked}
            modifier="primary"
            size="large"
            onClick={actions.confirm}
          >
            Confirm Selection
          </Button>
        </footer>
      </section>
    </Accordion>
  );
};

SelectDataAccordion.propTypes = {
  directInvestment: string,
  displayDisclaimer: bool,
  onConfirm: func.isRequired,
  stats: array.isRequired,
  selectedState: object,
  stepLevel: number
};

SelectDataAccordion.defaultProps = {
  directInvestment: '',
  stepLevel: 2
};

export default SelectDataAccordion;
