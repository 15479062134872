import { useEffect, useState } from 'react';
import { Card, Spinner, Link } from '@comcast-sir/ui-library';
import { useParams } from 'react-router-dom';

import Header from '../Header/Header';

import { useKeyResources } from '../../hooks';

const KeyResources = () => {
  const { resourceType } = useParams();
  const keyResourcesContent = useKeyResources(resourceType);

  const [resources, setResources] = useState([]);
  const [pageTitle, setPageTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (keyResourcesContent && keyResourcesContent.length > 0) {
      setIsLoading(true);
      setResources(keyResourcesContent);
      setPageTitle(keyResourcesContent[0].resourceType);
    }
  }, [keyResourcesContent]);

  const baseClass = 'sir-key-resources';

  return (
    <div className={baseClass}>
      <Header title={pageTitle} />
      {isLoading &&
        resources.map(
          resource =>
            resource.attachments && (
              <Card key={resource.id} className={`${baseClass}-card`}>
                <div className="sir-type-body sir-type-body--xsmall sir-action-card-subtitle">
                  {resource.attachments.type
                    .replace('application/', '')
                    .toUpperCase()}
                </div>
                <Link
                  external
                  to={`/view-pdf?pdf=${resource.attachments.filename.replaceAll(
                    ' ',
                    ''
                  )}`}
                  className={`${baseClass}-card-title`}
                >
                  {resource.title
                    ? resource.title
                    : resource.attachments.filename}
                </Link>
              </Card>
            )
        )}
      <Spinner show={!isLoading} />
    </div>
  );
};

export default KeyResources;
