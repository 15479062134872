import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useIdleTimer } from 'react-idle-timer';

import { loginRequest, apiRequest } from '../../authConfig';
import { useHistory } from 'react-router';

export const useAuth = () => {
  const { instance, accounts } = useMsal();
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState(null);
  const user = accounts && accounts.length ? accounts[0] : {};
  const history = useHistory();

  const login = () => {
    try {
      // Login via redirect
      instance.loginRedirect(loginRequest);
    } catch (err) {
      setAuthenticated(false);
      setError(err);
    }
  };
  const logout = () => {
    const logoutRequest = {
      account: user,
      onRedirectNavigate: () => false
    };

    instance.logoutRedirect(logoutRequest);
    history.push('/');
  };

  // Logout after 30 minutes of inactivity
  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: logout,
    debounce: 500,
    crossTab: {
      type: 'localStorage'
    }
  });

  const actions = {
    login,
    logout
  };

  return [{ user, isAuthenticated, error }, actions];
};

export const useAccountName = () => {
  if (process.env.NODE_ENV === 'development') {
    const [account] = useAuth();
    const username = account.user && account.user.username;

    return username;
  } else {
    const [account] = useAuth();
    const claims = account.user && account.user.idTokenClaims;
    const username = claims && claims.userprincipalname;

    return username;
  }
};

export const useBasicAuthentication = () => {
  const username = useAccountName();
  const withKey = `${username}_${apiRequest.apiKey}`;

  return username ? `Basic ${btoa(withKey)}` : undefined;
};

export const useFetch = (params, defaultValue) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(defaultValue);
  const authentication = useBasicAuthentication();
  const clearResponse = () => {
    setResponse(defaultValue);
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { url, ...options } = params;
        const res = await fetch(url, {
          ...options,
          headers: {
            ...options.headers,
            Authorization: authentication,
            'Content-Type': 'application/json'
          }
        });

        if (res.status === 200 || res.status === 201) {
          const json = await res.json();

          if (json.data) {
            setResponse(json.data);
            setLoading(false);
          } else {
            throw Error('No data');
          }
        } else {
          throw Error('No data');
        }
      } catch (e) {
        setError(e.message);
      }
    };

    if (params) {
      setLoading(true);
      fetchData();
    }
  }, [params]);

  const request = {
    error,
    loading
  };

  return [response, request, clearResponse];
};

export const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth);
  // Add a second state variable "height" and default it to the current window height
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      // Set the height in state as well as the width
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  // Return both the height and width
  return { width, height };
};
