import { useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import ReportDocument from '../ReportDocument/ReportDocument';
import {
  Button,
  Card,
  Heading,
  Link,
  Modal,
  SkeletonLoader,
  Text
} from '@comcast-sir/ui-library';

import { getPrintDescription } from '../../helpers';
import {
  useAuth,
  useContentByScreen,
  useSavedReport,
  useExportReportActions
} from '../../hooks';

import Header from '../Header/Header';
import ReportViewer from '../ReportViewer/ReportViewer';
import ReportExportContent from './ReportExportContent';

const ReportExport = () => {
  const [{ user }] = useAuth();
  const [report, loaded] = useSavedReport();
  const [savedActions] = useExportReportActions(report);
  const content = useContentByScreen('report-export');
  const globalContent = useContentByScreen('global');
  const [showModal, setModal] = useState(false);
  const hasError = report.error || false;
  const printDescription = getPrintDescription(globalContent);
  const showPrintModal = () => setModal(true);
  const baseClass = 'sir-report-export';
  const pdfClass = `${baseClass}-pdf`;
  const headingTitle = report?.cityName
    ? content['heading-2']
    : content.heading;
  const overview = globalContent['city-overview'];
  const reportDocument = (
    <ReportDocument report={report} stats={report.stats} overview={overview} />
  );
  const reportPDF = pdf(reportDocument);

  return (
    <div className={baseClass}>
      {loaded && <Header title={headingTitle} />}

      <Card className={`${pdfClass} ${hasError ? `${pdfClass}--error` : ''}`}>
        <ReportExportContent
          content={content}
          globalContent={globalContent}
          loaded={loaded}
          report={report}
          showModal={showPrintModal}
          user={user}
          reportPDF={reportPDF}
        />
        {!hasError && (
          <div className={`${pdfClass}-preview`}>
            <div className={`${pdfClass}-preview-controls`}>
              <Heading size="xsmall">PDF Preview</Heading>
              {loaded ? (
                <Button
                  modifier="secondary"
                  iconProps={{ name: 'edit' }}
                  onClick={savedActions.edit}
                >
                  Edit
                </Button>
              ) : null}
            </div>
            <SkeletonLoader height="560px" loaded={loaded}>
              <ReportViewer reportDocument={reportDocument} />
            </SkeletonLoader>
          </div>
        )}
      </Card>
      <Modal
        id="print-reports"
        title={globalContent['print-report-heading']}
        show={showModal}
        onClose={() => setModal(false)}
      >
        {printDescription}
        <Text element="p" modifier="bold">
          {globalContent['print-report-warning']}
        </Text>
        <footer className="sir-modal-footer">
          <Link
            to={`mailto:StateReports_Mailbox@comcast.com?subject=Print Request&body=%0D%0AReport reference ID: ${report.id}`}
            modifier="primary"
            iconProps={{ name: 'external', title: 'Mail link' }}
            external
          >
            {content['email-action']}
          </Link>
        </footer>
      </Modal>
    </div>
  );
};

export default ReportExport;
