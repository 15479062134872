export const getStateName = (states, stateAbbr) => {
  const match = states.find(state => state.abbr === stateAbbr);

  if (!match) {
    return;
  }

  return match.name;
};

export const getSavedStatIds = report => {
  if (!report || !report.stats) {
    return [];
  }

  // Works with stats in both formats
  return report.stats.map(stat => stat.id || Number(stat));
};

export const getSavedStateAbbr = report => {
  if (!report) {
    return;
  }

  return report.stateAbbr || report.state;
};

export const getInitialStats = (stats, report) => {
  const savedStats = getSavedStatIds(report);
  const initialStats = stats.map(stat => {
    const order = savedStats.includes(stat.id)
      ? savedStats.indexOf(stat.id) + 1
      : '';

    return {
      ...stat,
      order
    };
  });

  return initialStats;
};

export const getSelectedStats = stats => {
  return stats
    .filter(stat => stat.order)
    .slice(0)
    .sort((a, b) => a.order - b.order);
};

export const reorderStats = (allStats, selectedId) => {
  const selectedStat = allStats.find(stat => stat.id === selectedId);
  const updatedStats = allStats.map(stat => {
    if (stat.id === selectedId) {
      return { ...stat, order: '' };
    }
    const selectedPosition = selectedStat.order;

    if (stat.order && stat.order > selectedPosition) {
      return { ...stat, order: stat.order - 1 };
    }
    return stat;
  });

  return updatedStats;
};

export const getInitialGroup = (groups, report, saved) => {
  if (report && !saved) {
    const { featureType } = report;
    const selectedGroup = groups.find(group => group.value === featureType);

    if (selectedGroup) {
      return selectedGroup;
    }
  }

  const [firstOption] = groups;

  return firstOption;
};

export const getInitialOptions = (options, selectedId, saved) => {
  const initialOptions = options.map((option, index) => {
    const optionId = option.label.includes('Photo Header')
      ? option.photoHeaderId
      : option.featureId;
    const checked =
      !saved && selectedId ? selectedId === optionId : index === 0;

    return {
      id: option.id,
      label: option.label,
      checked
    };
  });

  return initialOptions;
};

export const getModifiedDate = report => {
  if (!report.modifiedDate) {
    return;
  }

  return new Date(report.modifiedDate).toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};
