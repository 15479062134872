import { Component } from 'react';
import { node, string } from 'prop-types';
import { Text } from '@comcast-sir/ui-library';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({
      hasError: true
    });
  }

  render() {
    const { children, message } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div className="sir-error">
          <Text className="sir-error-message" element="p">
            {message}
          </Text>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: node,
  message: string
};

ErrorBoundary.defaultProps = {
  message:
    'Sorry, an error has occurred. Please try refreshing the application.'
};

export default ErrorBoundary;
