import { array, func, string, bool } from 'prop-types';
import { Button, Select } from '@comcast-sir/ui-library';

import { useSelectedState } from '../../hooks';
import { useState } from 'react';

const StateSelect = props => {
  const {
    onSubmit,
    submitText,
    states,
    cities,
    onCitySubmit,
    investmentReportType,
    toggleInvestmentReportType,
    showSwitchButton
  } = props;

  const selectedState = useSelectedState();
  const defaultValue = selectedState ? selectedState.abbr : '';
  const [selectedStateValue, setSelectedStateValue] = useState(defaultValue);

  const [selectedCityValue, setSelectedCityValue] = useState(
    selectedState ? selectedState.city : ''
  );

  const handleStateChange = event => {
    setSelectedStateValue(event.target.value);
  };

  const handleCityChange = event => {
    setSelectedCityValue(event.target.value);
  };

  if (!states || !states.length) {
    return null;
  }

  return (
    <>
      <form
        className="sir-state-select-form"
        onSubmit={investmentReportType === 'City' ? onCitySubmit : onSubmit}
      >
        {investmentReportType === 'State' ? (
          <Select
            id="state-select"
            label="State"
            name="state"
            size="large"
            onChange={handleStateChange}
            value={selectedStateValue}
          >
            <option value="">Choose a state</option>
            {states.map(state => (
              <option key={state.abbr} value={state.abbr}>
                {state.name}
              </option>
            ))}
          </Select>
        ) : (
          <Select
            id="city-select"
            label="City"
            name="city"
            size="large"
            onChange={handleCityChange}
            value={selectedCityValue}
          >
            <option value="">Choose a city</option>
            {cities.map(city => (
              <option
                key={`${city.stateAbbrev} - ${city.name}`}
                value={city.name}
                data-state-abbrev={city.stateAbbrev}
              >
                {city.stateAbbrev} - {city.name}
              </option>
            ))}
          </Select>
        )}
        <Button
          disabled={
            investmentReportType === 'State'
              ? !selectedStateValue
              : !(selectedCityValue && Array.isArray(cities) && cities.length)
          }
          modifier="primary"
          size="large"
          type="submit"
        >
          {submitText}
        </Button>
      </form>
      {showSwitchButton && (
        <Button
          modifier="link"
          size="xsmall"
          onClick={toggleInvestmentReportType}
        >
          {`Switch to ${
            investmentReportType === 'State' ? 'City' : 'State'
          } selection`}
        </Button>
      )}
    </>
  );
};

StateSelect.propTypes = {
  onSubmit: func.isRequired,
  submitText: string.isRequired,
  states: array.isRequired,
  cities: array.isRequired,
  onCitySubmit: func.isRequired,
  investmentReportType: string,
  toggleInvestmentReportType: func,
  showSwitchButton: bool
};

StateSelect.defaultProps = {
  showSwitchButton: false
};

export default StateSelect;
