import { bool, func, string } from 'prop-types';

const Radio = (props) => {
  const { checked, disabled, id, label, onChange } = props;
  const baseClass = 'sir-form-field';
  const radioClass = `${baseClass}-radio`;
  const labelClass = `${baseClass}-label`;

  return (
    <div className={baseClass}>
      <input
        className={radioClass}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        id={id}
        type="radio"
        value={id}
      />
      <label className={labelClass} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

Radio.propTypes = {
  /**
   * checked state
   */
  checked: bool,
  /**
   * disabled state
   */
  disabled: bool,
  /**
   * unique identifier
   */
  id: string.isRequired,
  /**
   * label text
   */
  label: string.isRequired,
  /**
   * change handler
   */
  onChange: func
};

Radio.defaultProps = {
  checked: false,
  disabled: false
};

export default Radio;
