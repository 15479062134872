import { node, string } from 'prop-types';
import { Heading, Link } from '@comcast-sir/ui-library';

const Header = props => {
  const { children, title } = props;
  const baseClass = 'sir-app-header';

  return (
    <header className={baseClass}>
      <div className={`${baseClass}-content`}>
        <Link to="/">Go to Dashboard</Link>
        <Heading element="h2">{title}</Heading>
      </div>
      {children ? (
        <div className={`${baseClass}-controls`}>{children}</div>
      ) : null}
    </header>
  );
};

Header.propTypes = {
  children: node,
  title: string
};

export default Header;
