import {
  Button,
  CopyClipboard,
  Modal,
  Select,
  Spinner,
  Text
} from '@comcast-sir/ui-library';

import { useContentByScreen, useSavedReports } from '../../hooks';
import Header from '../Header/Header';
import SavedReport from './SavedReport';
import SavedReportsEmpty from './SavedReportsEmpty';

const SavedReports = () => {
  const [reports, data, actions] = useSavedReports();
  const content = useContentByScreen('custom-reports');
  const modalContent = useContentByScreen('global');
  const hasReports = Boolean(reports.length);
  const baseClass = 'sir-saved-reports';

  return (
    <div className={baseClass}>
      <Header title={content.heading}>
        {hasReports ? (
          <Select
            label="Sort by:"
            id="report-sort"
            size="medium"
            onChange={actions.sort}
            value={data.currentSort}
          >
            <option value="modifiedDate:dsc">
              Last modified date (Descending)
            </option>
            <option value="modifiedDate:asc">
              Last modified date (Ascending)
            </option>
            <option value="title:asc">Report name (A-Z)</option>
            <option value="title:dsc">Report name (Z-A)</option>
          </Select>
        ) : null}
      </Header>
      {!hasReports && !data.loading ? (
        <SavedReportsEmpty content={content} />
      ) : (
        reports.map(report => (
          <SavedReport key={report.id} report={report} actions={actions} />
        ))
      )}
      <Spinner show={data.loading} />
      <Modal
        id="share-link"
        title={modalContent['share-link-heading']}
        show={data.shareModal}
        onClose={actions.closeShareModal}
      >
        <Text element="p">{modalContent['share-link-description']}</Text>
        <Text element="p">{modalContent['share-link-warning']}</Text>
        <CopyClipboard
          label="Share Link"
          value={`${window.location.origin}/report/${data.reportId}`}
        />
      </Modal>
      <Modal
        id="delete-report"
        className="sir-modal--delete"
        title={modalContent['delete-report-heading']}
        show={data.deleteModal}
        onClose={actions.closeDeleteModal}
      >
        <Text element="p">{modalContent['delete-report-description']}</Text>
        <Text element="p" modifier="bold">
          {modalContent['delete-report-warning']}
        </Text>
        <div className="sir-modal-footer">
          <Button modifier="secondary" onClick={actions.closeDeleteModal}>
            Cancel
          </Button>
          <Button
            modifier="danger"
            onClick={actions.deleteReport.bind(null, data.reportId)}
          >
            Confirm Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default SavedReports;
