import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from '@azure/msal-react';
import { Route } from 'react-router-dom';
import { Spinner } from '@comcast-sir/ui-library';

import Home from '../Home/Home';
import Login from '../Login/Login';

import { ContentContext, useContent } from '../../hooks';

const App = () => {
  const content = useContent();

  return (
    <div className="sir-app">
      <Spinner show={!content} />
      <ContentContext.Provider value={content}>
        <Route path="/">
          <AuthenticatedTemplate>
            <Home />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Login />
          </UnauthenticatedTemplate>
        </Route>
      </ContentContext.Provider>
    </div>
  );
};

export default App;
