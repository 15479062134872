import { useRef, useEffect } from 'react';
import { bool, string } from 'prop-types';

const Checkbox = (props) => {
  const { id, label, indeterminate, ...otherProps } = props;
  const baseClass = 'sir-form-field';
  const labelClass = 'sir-form-field-label';
  const checkRef = useRef();

  useEffect(() => {
    checkRef.current.indeterminate = indeterminate;
  }, [indeterminate]);

  return (
    <div className={baseClass}>
      <input
        className={`${baseClass}-checkbox`}
        type="checkbox"
        id={id}
        ref={checkRef}
        {...otherProps}
      />
      <label className={labelClass} htmlFor={id}>
        <span className={`${labelClass}-text`}>{label}</span>
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  /**
   * the checkbox's current checked/unchecked state
   */
  checked: bool,

  /**
   * sets the checkbox to indeterminate
   */
  indeterminate: bool,

  /**
   * checkbox ID and label htmlFor value
   */
  id: string.isRequired,

  /**
   * label text
   */
  label: string
};

Checkbox.defaultProps = {
  checked: false,
  indeterminate: false
};

export default Checkbox;
