import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { array, string } from 'prop-types';

import ReportStyles from './ReportStyles';

const styles = StyleSheet.create(ReportStyles);

const ReportLegend = props => {
  const { legend, legendColors, caption } = props;

  if (!legend) {
    return null;
  }

  const legendItems = legend.split('\n');

  return (
    <View
      style={[styles.legendWrapper, { flexWrap: caption ? 'nowrap' : 'wrap' }]}
    >
      {legendItems.map((text, index) => (
        <View
          key={index}
          style={[
            styles.legend,
            { marginRight: index === legendItems.length - 1 ? 0 : undefined }
          ]}
        >
          <View
            style={[
              styles.legendKey,
              {
                backgroundColor: legendColors[index]
              }
            ]}
          />
          <Text style={styles.legendText}>{text}</Text>
        </View>
      ))}
    </View>
  );
};

ReportLegend.propTypes = {
  legend: string,
  legendColors: array,
  caption: string
};

ReportLegend.defaultProps = {
  legendColors: []
};

export default ReportLegend;
