import { string, node, oneOf } from 'prop-types';
import cx from 'classnames';

export const TAG_PROPS = {
  size: ['small', 'xsmall'],
  color: ['purple', 'blue']
};

const Tag = (props) => {
  const { children, className, size, color, ...otherProps } = props;
  const baseClass = 'sir-tag';
  const sizeClass = `${baseClass}--${size}`;
  const tagColorClass = `${baseClass}--${color}`;

  return (
    <span
      className={cx(baseClass, sizeClass, tagColorClass, className)}
      {...otherProps}
    >
      {children}
    </span>
  );
};

Tag.propTypes = {
  className: string,
  children: node,
  size: oneOf(TAG_PROPS.size),
  color: oneOf(TAG_PROPS.color)
};

Tag.defaultProps = {
  size: 'small',
  color: 'purple'
};

export default Tag;
