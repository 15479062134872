import { arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import cx from 'classnames';

import ActionMenu from '../ActionMenu/ActionMenu';
import Card from '../Card/Card';
import EditForm from '../EditForm/EditForm';
import Link from '../Link/Link';
import Text from '../Text/Text';
import Tag, { TAG_PROPS } from '../Tag/Tag';

const ActionCard = (props) => {
  const {
    actions,
    className,
    editable,
    id,
    onSubmit,
    title,
    to,
    subtitle,
    triggerLabel,
    tag,
    tagColor
  } = props;
  const baseClass = 'sir-action-card';
  const editableClass = editable && `${baseClass}--editable`;

  const renderTitle = () => {
    if (editable) {
      return (
        <EditForm id={`edit-${id}`} initialValue={title} onSubmit={onSubmit} />
      );
    }

    if (to) {
      return (
        <Link to={to} className={`${baseClass}-title`}>
          {title}
        </Link>
      );
    }

    return (
      <Text
        className={`${baseClass}-title`}
        element="h2"
        type="display"
        size="small"
      >
        {title}
      </Text>
    );
  };

  return (
    <Card className={cx(baseClass, editableClass, className)}>
      <div className={`${baseClass}-content`}>
        {tag && <Tag color={tagColor}>{tag}</Tag>}
        {subtitle ? (
          <Text className={`${baseClass}-subtitle`} size="xsmall">
            {subtitle}
          </Text>
        ) : null}
        {renderTitle()}
      </div>
      <div className={`${baseClass}-controls`}>
        <ActionMenu actions={actions} triggerLabel={triggerLabel} />
      </div>
    </Card>
  );
};

ActionCard.propTypes = {
  actions: arrayOf(shape({ label: string.isRequired })),
  className: string,
  editable: bool,
  id: string.isRequired,
  onSubmit: func,
  title: string.isRequired,
  to: string,
  subtitle: string,
  triggerLabel: string,
  tag: string,
  tagColor: oneOf(TAG_PROPS.color)
};

ActionCard.defaultProps = {
  editable: false
};

export default ActionCard;
