import {
  Accordion,
  Button,
  FeaturedContent,
  RadioGroup,
  Select,
  Text
} from '@comcast-sir/ui-library';
import { bool, func, object, number } from 'prop-types';

import { useContentByScreen, useSelectedGroup, useViewport } from '../../hooks';

import quote from '../../assets/images/quote.png';

const SelectContentAccordion = props => {
  const { expanded, onConfirm, onToggle, reportContent, stepLevel } = props;
  const [data, featuredContent, actions] = useSelectedGroup(
    reportContent,
    onConfirm
  );
  const content = useContentByScreen('report-builder');
  const featuredImage = (featuredContent && featuredContent.image) || quote;
  const options = reportContent.options;
  const baseClass = 'sir-accordion-content-featured';
  const { width } = useViewport();
  const isDesktop = width >= 991;
  const city = reportContent?.cityName;

  return (
    <Accordion
      checked={data.confirmed}
      expanded={expanded}
      id="content-selection"
      label={`Step ${stepLevel}: Select Featured Content`}
      onClick={onToggle}
      trigger={
        <div
          key="featured-trigger"
          className="sir-accordion-content-featured-selection"
        >
          <Text size="small" type="display" style={{ fontWeight: 500 }}>
            {data.label}
          </Text>
        </div>
      }
    >
      {!city && (
        <header className={`${baseClass}-header`}>
          <Text element="p" modifier="bold">
            {content['featured-heading']}
          </Text>
          <Text element="p">{content['featured-description']}</Text>
          {options ? (
            <Select
              id="featured-content"
              label="Select content type"
              hideLabel
              onChange={actions.handleSelect}
              value={data.featureType}
            >
              {options.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          ) : null}
        </header>
      )}
      <div className={`${baseClass}-body`} style={{ margin: city ? '0' : '' }}>
        <Text element="h4" modifier="bold">
          {!city ? content['options-heading'] : content['map-options-heading']}
        </Text>
        <div className="sir-featured-options-preview">
          <div className="sir-featured-options">
            {data.options && data.options.length ? (
              <RadioGroup
                name={data.featureType}
                options={data.options}
                onChange={actions.handleChange}
              />
            ) : null}
          </div>
          <div
            className={`sir-featured-preview ${
              isDesktop ? 'sir-report-preview--mini' : ''
            }`}
          >
            <FeaturedContent
              {...featuredContent}
              featureType={data.featureType}
              featuredImage={featuredImage}
            />
            {!isDesktop && (
              <div className="sir-feature-preview-mobile-note sir-type-body--small">
                Note: Featured content preview on mobile devices for reference
                purposes only. Format will vary in your customized report.
              </div>
            )}
          </div>
        </div>
      </div>
      <footer className="sir-report-builder-footer">
        <Button modifier="primary" size="large" onClick={actions.handleConfirm}>
          Confirm Selection
        </Button>
      </footer>
    </Accordion>
  );
};

SelectContentAccordion.propTypes = {
  expanded: bool,
  onConfirm: func.isRequired,
  onToggle: func.isRequired,
  reportContent: object,
  stepLevel: number
};

SelectContentAccordion.defaultProps = {
  stepLevel: 3
};

export default SelectContentAccordion;
