(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("prop-types"), require("react"), require("react-router-dom"));
	else if(typeof define === 'function' && define.amd)
		define("sir-ui-library", ["PropTypes", "React", "ReactRouterDOM"], factory);
	else if(typeof exports === 'object')
		exports["sir-ui-library"] = factory(require("prop-types"), require("react"), require("react-router-dom"));
	else
		root["sir-ui-library"] = factory(root["PropTypes"], root["React"], root["ReactRouterDOM"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__343__, __WEBPACK_EXTERNAL_MODULE__639__, __WEBPACK_EXTERNAL_MODULE__620__) => {
return 