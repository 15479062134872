import cx from 'classnames';
import { oneOf, string } from 'prop-types';

export const iconProps = {
  modifiers: ['default', 'white', 'primary', 'error', 'success', 'gray']
};

const Icon = (props) => {
  const { name, modifier, title } = props;
  const path = `#icon-${name}`;
  const baseClass = 'sir-icon';
  const modifierClass = modifier ? `${baseClass}--${modifier}` : '';
  const titleId = `icon-${name}-title`;
  const accessibleAttr = title
    ? { ['aria-labelledby']: titleId }
    : { ['aria-hidden']: true };

  return (
    <svg
      className={cx(baseClass, modifierClass)}
      focusable="false"
      role="img"
      {...accessibleAttr}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <use xlinkHref={path} width="100%" height="100%" />
    </svg>
  );
};

Icon.propTypes = {
  /**
   * name of the icon to display
   */
  name: string.isRequired,

  /**
   * variation of the icon to display
   * optional, must be one of: default, white, primary, error, success, gray
   */
  modifier: oneOf(iconProps.modifiers),

  /**
   * the SVG's `title` text
   */
  title: string.isRequired
};

export default Icon;
