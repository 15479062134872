import { Card, Heading, Text } from '@comcast-sir/ui-library';
import { array, func, string } from 'prop-types';

import logo from '../../assets/images/logo-comcast.png';
import statePreview from '../../assets/images/state-report-preview.jpg';
import cityPreview from '../../assets/images/city-report-preview.png';
import { CITY } from '../../constants';

import { fixLink, replaceLinks, withHeadingLink } from '../../helpers';
import { useContentByScreen } from '../../hooks';

import StateSelect from '../StateSelect/StateSelect';
// import InvestmentReportTypeSelect from '../InvestmentReportTypeSelect/InvestmentReportTypeSelect';

const Dashboard = props => {
  const {
    onStateSelect,
    states,
    cities,
    onCitySelect,
    investmentReportType,
    toggleInvestmentReportType
  } = props;
  const content = useContentByScreen('dashboard');
  const baseClass = 'sir-dashboard';
  const cardClass = `${baseClass}-card`;
  const resourceCardClass = `${baseClass}-resource-card`;
  const keyResourcesLink = fixLink(content['box-2-link']);
  const supportLink = fixLink(content['box-4-link']);

  return (
    <div className={baseClass}>
      <Card className={cardClass}>
        <div className={`${cardClass}-content`}>
          <div className={`${cardClass}-content--primary`}>
            <img
              className={`${baseClass}-logo`}
              alt="Comcast NBCUniversal logo"
              src={logo}
            />
            <Heading element="h2" withLinebreak>
              {content.heading}
            </Heading>
            <Text element="p" style={{ fontWeight: 500 }}>
              {content.description}
            </Text>
            {/* TODO: uncomment below when you want to display the radio select option to allow toggling between creating state or city reports */}
            {/* <InvestmentReportTypeSelect
              investmentReportType={investmentReportType}
              toggleInvestmentReportType={toggleInvestmentReportType}
            ></InvestmentReportTypeSelect> */}
            <StateSelect
              toggleInvestmentReportType={toggleInvestmentReportType}
              investmentReportType={investmentReportType}
              label="State"
              states={states}
              onSubmit={onStateSelect}
              submitText="Create a new report"
              cities={cities}
              onCitySubmit={onCitySelect}
            />
          </div>
          <div className={`${cardClass}-content--secondary`}>
            <img
              className="sir-report-preview-image"
              alt="State Investment Report preview"
              src={investmentReportType === CITY ? cityPreview : statePreview}
            />
          </div>
        </div>
      </Card>
      <div className={`${baseClass}-card-grid-wrapper`}>
        <Text element="h2" type="display" modifier="bold" size="small">
          {content.subheading}
        </Text>
        <div className={`${baseClass}-card-grid`}>
          <Card className={resourceCardClass}>
            <div className={`${resourceCardClass}-header`}>
              {withHeadingLink(content['box-1-link'], content['box-1-heading'])}
            </div>
            <div className={`${resourceCardClass}-body`}>
              {replaceLinks(content['box-1-description'])}
            </div>
          </Card>
          <Card className={resourceCardClass}>
            <div className={`${resourceCardClass}-header`}>
              {withHeadingLink(keyResourcesLink, content['box-2-heading'])}
            </div>
            <div className={`${resourceCardClass}-body`}>
              {replaceLinks(content['box-2-description'])}
            </div>
          </Card>
          <Card className={resourceCardClass}>
            <div className={`${resourceCardClass}-header`}>
              {withHeadingLink(content['box-3-link'], content['box-3-heading'])}
            </div>
            <div className={`${resourceCardClass}-body`}>
              {replaceLinks(content['box-3-description'])}
            </div>
          </Card>
          <Card className={resourceCardClass}>
            <div className={`${resourceCardClass}-header`}>
              {withHeadingLink(supportLink, content['box-4-heading'])}
            </div>
            <div className={`${resourceCardClass}-body`}>
              {replaceLinks(content['box-4-description'])}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  onStateSelect: func.isRequired,
  states: array.isRequired,
  cities: array.isRequired,
  onCitySelect: func.isRequired,
  investmentReportType: string,
  toggleInvestmentReportType: func
};

export default Dashboard;
