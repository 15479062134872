import { string } from 'prop-types';
import Text from '../Text/Text';

const Footer = (props) => {
  const { copyright } = props;
  const baseClass = 'sir-footer';
  const copyrightText = `©️ ${new Date().getFullYear()} ${copyright}`;

  return (
    <div className={baseClass}>
      <hr className="sir-divider" />
      <Text className={`${baseClass}-copyright`} element="small" size="tiny">
        {copyrightText}
      </Text>
    </div>
  );
};

Footer.propTypes = {
  copyright: string
};

Footer.defaultProps = {
  copyright: ''
};

export default Footer;
