import { array, bool } from 'prop-types';

import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
import Text from '../Text/Text';

const StatGrid = (props) => {
  const { loaded, stats } = props;
  const baseClass = 'sir-report-preview-stat';
  const temp = Array.from(Array(6).keys());
  const statNunUpClass = (statSize) => {
    switch (statSize) {
      case 3:
        return `${baseClass}--three-up`;
      case 4:
        return `${baseClass}--four-up`;
      default:
        return '';
    }
  };

  if (!loaded) {
    return (
      <div className={`${baseClass}-grid`}>
        {temp.map((index) => {
          return (
            <div key={index} className={baseClass}>
              <SkeletonLoader />
              <SkeletonLoader />
              <SkeletonLoader />
              <SkeletonLoader />
              <SkeletonLoader />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={`${baseClass}-grid`}>
      {stats.map((stat) => {
        const caption = stat.caption.split('\n');

        return (
          <div
            key={stat.id}
            className={`${baseClass} ${statNunUpClass(stats.length)}`}
          >
            <Text className={`${baseClass}-figure`}>{stat.figure}</Text>
            {caption.map((line, index) =>
              line ? (
                <Text
                  key={index}
                  className={`${baseClass}-caption`}
                  element="p"
                >
                  {line}
                </Text>
              ) : (
                <p
                  key={index}
                  className={`${baseClass}-caption ${baseClass}-caption--empty`}
                />
              )
            )}
            {stat.footnote ? (
              <Text
                className={`${baseClass}-footnote`}
                type="display"
                size="tiny"
              >
                {stat.footnote}
              </Text>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

StatGrid.propTypes = {
  loaded: bool,
  stats: array.isRequired
};

StatGrid.defaultProps = {
  loaded: true
};

export default StatGrid;
