import { string } from 'prop-types';
import cx from 'classnames';
import { captureStrWithBracketArrayGroup } from '../../utils.js';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
import Text from '../Text/Text';

const DirectInvestment = (props) => {
  const { content, frame } = props;
  const investment = content && captureStrWithBracketArrayGroup(content);
  const loaded = Boolean(investment);
  const baseClass = 'sir-report-preview-investment';
  const loadingClass = !loaded && `${baseClass}--loading`;

  return (
    <div className={`${baseClass}-wrapper`}>
      {frame ? (
        <img className={`${baseClass}-frame`} alt="" src={frame} />
      ) : null}
      <div className={cx(baseClass, loadingClass)}>
        <SkeletonLoader width="630px" loaded={loaded}>
          <Text className={`${baseClass}-text`} element="p">
            {investment ? investment[1] : null}
            <Text className={`${baseClass}-figure`}>
              {investment ? investment[2] : null}
            </Text>
            {investment ? investment[3] : null}
          </Text>
        </SkeletonLoader>
        <SkeletonLoader width="585px" loaded={loaded} />
        <SkeletonLoader width="540px" loaded={loaded} />
      </div>
    </div>
  );
};

DirectInvestment.propTypes = {
  content: string.isRequired,
  frame: string
};

export default DirectInvestment;
