import { forwardRef } from 'react';
import { bool, node, oneOf, string } from 'prop-types';
import cx from 'classnames';

export const CARD_PROPS = {
  modifier: ['default', 'neutral', 'success', 'warning', 'error']
};

// eslint-disable-next-line
const Card = forwardRef((props, ref) => {
  const { children, className, modifier, withShadow, ...otherProps } = props;
  const baseClass = 'sir-card';
  const containerClass = `${baseClass}-container`;
  const modifierClass =
    modifier === 'default'
      ? `${containerClass}--border`
      : `${baseClass}-communication--${modifier}`;
  const shadowClass = withShadow ? `${containerClass}--shadow` : '';

  return (
    <div
      className={cx(baseClass, modifierClass, shadowClass, className)}
      ref={ref}
      {...otherProps}
    >
      {children}
    </div>
  );
});

Card.propTypes = {
  children: node,
  className: string,
  modifier: oneOf(CARD_PROPS.modifier),
  withShadow: bool
};

Card.defaultProps = {
  modifier: 'default',
  withShadow: false
};

export default Card;
