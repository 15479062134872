import { Fragment } from 'react';
import cx from 'classnames';
import { bool, node, oneOf, shape, string } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import Icon from 'components/Icon/Icon';

export const LINK_PROPS = {
  size: ['large', 'medium', 'small', 'xsmall', 'tiny'],
  modifier: ['default', 'primary', 'secondary', 'danger']
};

const Link = (props) => {
  const {
    children,
    className,
    external,
    iconOnly,
    iconProps,
    modifier,
    size,
    to,
    ...otherProps
  } = props;
  const baseClass = 'sir-link';
  const btnClass = 'sir-btn';
  const iconName = iconProps && iconProps.name;
  const defaultModifier = modifier === 'default';
  const sizeClass = defaultModifier
    ? `${baseClass}--${size}`
    : `sir-btn--${size}`;
  const styleClass = defaultModifier
    ? `${baseClass}--${modifier}`
    : `${btnClass} ${btnClass}--${modifier}`;
  const labelClass = `${btnClass}-label`;
  const hiddenLabelClass = iconOnly && `${labelClass}--hidden`;
  const iconClass = iconName ? `${btnClass}--with-icon` : false;
  const externalClass = external ? `${baseClass}--external` : '';
  const iconOnlyClass = iconOnly ? `${iconClass}-only` : false;
  const classes = cx(
    baseClass,
    sizeClass,
    styleClass,
    externalClass,
    iconClass,
    iconOnlyClass,
    className
  );
  const linkIcon = iconName && <Icon title="" {...iconProps} />;
  const externalProps = external
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};

  if (!children) {
    return null;
  }

  const renderChildren = () =>
    iconProps ? (
      <Fragment>
        {linkIcon}
        <span className={cx(labelClass, hiddenLabelClass)}>{children}</span>
      </Fragment>
    ) : (
      children
    );

  if (external) {
    return (
      <a href={to} className={classes} {...externalProps} {...otherProps}>
        {renderChildren()}
        {defaultModifier ? (
          <Icon name="external" title="External link" modifier="primary" />
        ) : null}
      </a>
    );
  }

  return (
    <RouterLink to={to} className={classes} {...otherProps}>
      {renderChildren()}
    </RouterLink>
  );
};

Link.propTypes = {
  children: node,
  className: string,
  external: bool,
  iconOnly: bool,
  iconProps: shape({
    fill: string,
    name: string,
    title: string,
    position: string
  }),
  size: oneOf(LINK_PROPS.size),
  to: string,
  modifier: oneOf(LINK_PROPS.modifier)
};

Link.defaultProps = {
  iconOnly: false,
  size: 'medium',
  modifier: 'default'
};

export default Link;
