import { forwardRef, Fragment } from 'react';
import { bool, node, oneOf, shape, string } from 'prop-types';
import cx from 'classnames';

import Icon from '../Icon/Icon';

export const BUTTON_PROPS = {
  modifier: ['default', 'primary', 'secondary', 'danger', 'link'],
  size: ['large', 'medium', 'small', 'xsmall', 'tiny'],
  type: ['button', 'submit', 'reset']
};

// eslint-disable-next-line
const Button = forwardRef((props, ref) => {
  const {
    type,
    iconOnly,
    iconProps,
    modifier,
    size,
    disabled,
    children,
    className,
    ...otherProps
  } = props;

  const baseClass = 'sir-btn';
  const modifierClass = `${baseClass}--${modifier}`;
  const sizeClass = `${baseClass}--${size}`;
  const iconName = iconProps && iconProps.name;
  const iconClass = iconName ? 'sir-btn--with-icon' : false;
  const iconOnlyClass = iconOnly ? `${iconClass}-only` : false;
  const labelClass = 'sir-btn-label';
  const hiddenLabelClass = iconOnly && `${labelClass}--hidden`;

  const renderChildren = () => {
    if (iconName) {
      return (
        <Fragment>
          <Icon title="" {...iconProps} />
          {children ? (
            <span className={cx(labelClass, hiddenLabelClass)}>{children}</span>
          ) : null}
        </Fragment>
      );
    }

    return children;
  };

  return (
    <button
      type={type}
      className={cx(
        baseClass,
        modifierClass,
        sizeClass,
        iconClass,
        iconOnlyClass,
        className
      )}
      disabled={disabled}
      {...otherProps}
      ref={ref}
    >
      {renderChildren()}
    </button>
  );
});

Button.propTypes = {
  /**
   * Set custom class
   **/
  className: string,
  /**
   * Set an icon inside button
   **/
  iconProps: shape({ name: string }),
  /**
   * Set icon only
   **/
  iconOnly: bool,
  /**
   * Button type
   * must be one of: button, submit, reset
   * default: button
   **/
  type: oneOf(BUTTON_PROPS.type),

  /**
   * Button style variant
   * must be one of: primary, secondary, link
   * default: default
   */
  modifier: oneOf(BUTTON_PROPS.modifier),

  /**
   * Button size variant
   * must be one of: large, medium, small
   * default: medium
   */
  size: string,

  /**
   * Set the button to disabled state
   * default: false
   */
  disabled: bool,

  /**
   * Set the button content
   */
  children: node
};

Button.defaultProps = {
  disabled: false,
  iconOnly: false,
  modifier: 'default',
  size: 'medium',
  type: 'button'
};

export default Button;
