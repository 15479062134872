import { useEffect, useRef, useState } from 'react';
import { string } from 'prop-types';

import Button from '../Button/Button';
import Input from '../Input/Input';

const CopyClipboard = (props) => {
  const { submitLabel, successMessage, ...otherProps } = props;
  const [copySuccess, setCopySuccess] = useState('');
  const inputRef = useRef(null);
  const status = copySuccess ? 'success' : 'default';
  const baseClass = 'sir-copy-clipboard';

  useEffect(() => {
    if (copySuccess) {
      setTimeout(() => {
        setCopySuccess('');
      }, 3000);
    }
  }, [copySuccess]);

  const copyToClipboard = (event) => {
    event.preventDefault();

    inputRef.current.select();
    document.execCommand('copy');
    setCopySuccess(successMessage);
  };

  return (
    <form className={baseClass} onSubmit={copyToClipboard}>
      <Input
        id="copy-clipboard"
        type="textarea"
        ref={inputRef}
        readOnly
        message={copySuccess}
        status={status}
        {...otherProps}
      />
      <Button modifier="primary" size="large" type="submit">
        {submitLabel}
      </Button>
    </form>
  );
};

CopyClipboard.propTypes = {
  label: string.isRequired,
  submitLabel: string,
  successMessage: string,
  value: string
};

CopyClipboard.defaultProps = {
  successMessage: 'Copied!',
  submitLabel: 'Copy to clipboard'
};

export default CopyClipboard;
