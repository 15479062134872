import { array, string, func } from 'prop-types';
import { Modal, Spinner, Text } from '@comcast-sir/ui-library';

import {
  useContentByScreen,
  useReportBuilder,
  useReportData
} from '../../hooks';

import Header from '../Header/Header';
import ReportReview from './ReportReview';
import SelectContentAccordion from './SelectContentAccordion';
import SelectDataAccordion from './SelectDataAccordion';
import SelectStateAccordion from './SelectStateAccordion';
import SelectPhotoHeaderAccordion from './SelectPhotoHeaderAccordion';
import StateSelect from '../StateSelect/StateSelect';
import ReportError from './ReportError';

const ReportBuilder = props => {
  const { states, cities, investmentReportType, toggleInvestmentReportType } =
    props;
  const content = useContentByScreen('report-builder');
  const modalContent = useContentByScreen('global');
  const [reportContent, allStats, selectedState] = useReportData();
  const [data, actions] = useReportBuilder(reportContent);
  const baseClass = 'sir-report-builder';
  const cityName = selectedState?.city;

  if (data && data.report) {
    data.report.moreInfo =
      data.report.moreInfo && data.report.moreInfoUrl
        ? data.report.moreInfo.replace(data.report.moreInfoUrl, ``)
        : data.report.moreInfo;
  }

  const heading = cityName ? content['heading-2'] : content.heading;

  return (
    <div className={baseClass}>
      <Spinner show={data.showSpinner} />
      <Header title={data.savedReport ? content['edit-heading'] : heading} />
      <ReportError error={data.error} />
      <form onSubmit={actions.submit}>
        <SelectStateAccordion
          selectedState={selectedState}
          onChangeState={actions.showModal}
        />
        {cityName && (
          <SelectPhotoHeaderAccordion
            onConfirm={actions.confirmPhotoHeaderSelection}
            reportContent={reportContent}
          />
        )}
        <SelectDataAccordion
          selectedState={selectedState}
          directInvestment={reportContent.directInvestment}
          displayDisclaimer={reportContent.displayDisclaimer}
          stats={allStats}
          onConfirm={actions.confirmStats}
          stepLevel={cityName ? 3 : 2}
        />
        <SelectContentAccordion
          expanded={data.expanded}
          onConfirm={actions.confirmContent}
          onToggle={actions.toggle}
          reportContent={reportContent}
          stepLevel={cityName ? 4 : 3}
        />
        <ReportReview
          report={data.report}
          stats={data.stats}
          photoheader={data.photoheader}
          showBorder={data.photoheader ? false : true}
        />
      </form>
      <Modal
        id="change-state"
        title={
          cityName
            ? modalContent['change-city-heading']
            : modalContent['change-state-heading']
        }
        show={data.showModal}
        onClose={actions.hideModal}
      >
        <Text element="p">
          <>
            {cityName
              ? modalContent['change-city-description']
              : modalContent['change-state-description']}
          </>
        </Text>
        <Text element="p" modifier="bold">
          {cityName
            ? modalContent['change-city-warning']
            : modalContent['change-state-warning']}
        </Text>
        <StateSelect
          onSubmit={actions.changeState}
          onCitySubmit={actions.changeCity}
          submitText="Save"
          states={states}
          investmentReportType={investmentReportType}
          toggleInvestmentReportType={toggleInvestmentReportType}
          cities={cities}
        />
      </Modal>
    </div>
  );
};

ReportBuilder.propTypes = {
  states: array.isRequired,
  cities: array.isRequired,
  investmentReportType: string,
  toggleInvestmentReportType: func
};

export default ReportBuilder;
